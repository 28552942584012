<template>
    <modal hide-footer hideHeader centered autoOpen classBody="p-0" contentClass="overflow-hidden"
        dialogStyle="max-width:686px">
        <template #default="{hide}">
            <div class="bg-banner d-flex flex-column">
                <button type="button" class="btn-close btn-close-white position-absolute" aria-label="Close"
                    @click="hide" style="top:30px;right: 30px; transform: scale(1.5); box-shadow: none !important;">
                </button>
                <div class="row mt-5">
                    <div class="col-12">
                        <div class="col-7 text-center mx-auto">
                            <p class="title text-capitalize mb-0">Welcome to VIP Access!</p>
                            
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="col-10 text-center mx-auto mb-2">
                            <p class="subtitle mb-3">These are your keys to the world of TAFER.</p>
                            <p class="content-text">Please feel free to navigate and explore all the options available to you, including services and itineraries. We hope you have an unforgettable vacation!</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import modal from '../components/modal.vue'

export default{
    components:{
        modal,
    }
}
</script>
<style scoped>
.title{
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(1.5rem, 2vw + 1rem, 1.75rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: 500;
    line-height: var(--unnamed-line-spacing-43);
    letter-spacing: 2.4px;
    color: var(--unnamed-color-ffffff);
    
}

.subtitle {
    font-family: var(--unnamed-font-family-arpona);
    font-size: clamp(1rem, 1vw + 0.25rem, 1.5rem) !important;
    font-style: var(--unnamed-font-style-italic);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 0;
    color: var(--unnamed-color-dbaa4b);
}

.content-text {
    font-family: var(--unnamed-font-family-arpona);
    font-style: var(--unnamed-font-style-normal) normal 200;
    font-size: clamp(0.875rem, 2vw - 0.25rem, 1rem);
    color: var(--unnamed-color-ffffff);
    letter-spacing: 0px;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.bg-banner{
        position:relative;
        background: transparent linear-gradient(180deg, #11111DF2 0%, #11111D00 56%, #11111D00 100%) 0% 0% no-repeat padding-box;
        background-image:
        url('@/Content/images/banners/welcomeBanner.png');
        height: 611px;
        background-position: center;
        background-repeat: no-repeat!important ;
        background-size: cover;
        border-radius: 45px;
        filter: brightness(0.9);
    }

@media (max-width: 768px) {
    .bg-banner{
        position:relative;
        background: transparent linear-gradient(180deg, #11111DF2 0%, #11111D00 56%, #11111D00 100%) 0% 0% no-repeat padding-box;
        background-image:
        url('@/Content/images/banners/welcomeBanner.png');
        height: 611px;
        background-position: center;
        background-repeat: no-repeat!important ;
        background-size: cover;
        border-radius: 45px;
        filter: brightness(0.9);
    }
}
@media (min-width: 769px) {
    .bg-banner{
        position:relative;
        background: transparent linear-gradient(180deg, #11111DF2 0%, #11111D00 56%, #11111D00 100%) 0% 0% no-repeat padding-box;
        background-image:
        url('@/Content/images/banners/welcomeBanner.png');
        height: 611px;
        background-position: center;
        background-repeat: no-repeat!important ;
        background-size: cover;
        border-radius: 45px;
        filter: brightness(0.9);
    }
}


</style>