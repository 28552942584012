import { createStore } from 'vuex'

/* Estado Global 
 */
// import * as actions from './actions';
// import * as getters from './getters';
// import * as mutations from './mutations';
// import state from './state';
import modules from './modules';


export default createStore({
    namespaced: true,
    // actions,
    // mutations,
    // state,
    // getters,
    modules
});