// export const myMutation = (state) => {   

    ////ToDo

// };

// export const updateZones = (state, { zones, loading }) => {

//     state.zones = zones;
//     state.loadingZones = loading;

// };

// export const updateCatalogs = (state, { catalogs, loading }) => {

//     state.catalogs = catalogs;
//     state.loadingCatalogs = loading;

// };

// export const updateCategories = (state, { categories, loading }) => {

//     state.categories = categories;
//     state.loadingCategories = loading;

// };

export const myLiveChatClicked = (state, change) => {

    const keyChanges = ['clicked', 'hidden'];

    keyChanges.forEach(key => {
        if(change[key] != undefined){
            state.myLiveChat[key] = change[key];
        }
    });
}

export const onLoadMyLiveChat = (state) => {

    state.myLiveChat.loaded = true;
}