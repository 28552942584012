//Librerias Externas
import { createApp } from 'vue'
import './registerServiceWorker'

//Importaciones necesarias para el proyecto

import App from './App.vue'
import router from './router/router'
import store from './store'
import i18n from './translate/i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import Vue3Lottie from 'vue3-lottie'


//Archivos CSS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css';
import 'vue3-carousel/dist/carousel.css'
import '@/Content/css/Site.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue3-lottie/dist/style.css'

createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(VueSweetalert2)
.use(VueAxios,axios)
.use(Vue3Lottie)
.component('v-select', vSelect)
.mount('#app')
