<template>
    <modal hide-footer hideHeader centered autoOpen classBody="p-0" contentClass="overflow-hidden"
        dialogStyle="max-width:686px">
        <template #default="{hide}">
            <!-- <div class="promotion-spa d-flex flex-column">
                <button type="button" class="btn-close btn-close-white position-absolute" aria-label="Close"
                    @click="hide" style="top:30px;right: 30px; transform: scale(1.5); box-shadow: none !important;">
                </button>
                <div class="row mt-5">
                    <div class="col-12 custom-size">
                        <div class="col-12 mx-auto text-center">
                            <img style="filter: brightness(1.5); height: 86px;"
                                src="@/Content/images/modules/spaImage/logo.png" />
                            <p class="spa-title mt-lg-5 mt-3 mb-0">Essential Vacation Experience</p>
                            <p class="spa-subtitle mb-3">A 25 min Scalp massage + 25 min Aloe Vera Restorative
                                Facial + one hour of our exclusive hydrotherapy area.</p>
                            <p class="spa-price-old mb-3">$259 USD</p>
                            <div class="col-auto mx-auto mb-3">
                                <span class="spa-price-new border-2 border-top border-bottom px-4">$195 USD</span>
                            </div>
                            <button class="btn btn-vip-gold" @click="openURL('https://wa.me/+523223273663')">Book
                                through your
                                concierge</button>
                            <p class="spa-content mt-2 mb-lg-3 mb-3">Book through Concierge and get this
                                exclusive spa package for <span style="color: #DBAA4B;">only 195 USD</span></p>
                            <p class="spa-describe mb-2 mb-lg-3">Exclusive promotion available through your
                                concierge.Cannot
                                be combined with other promotions, discount or resort credit.</p>
                            <p class="spa-charges">*12.5% service charge and 16% local tax included.</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="position-relative promotion-spa overflow-hidden">
                <button type="button" class="btn-close btn-close-white position-absolute" aria-label="Close"
                    @click="hide" style="top:30px;right: 30px; transform: scale(1.5); box-shadow: none !important;">
                </button>
                        <div class="container-fluid custom-size-img" style="padding-top: 2.5em;">
                            <div class="col-12">
                                <div class="col-12 mx-auto text-center">
                                    <img style="filter: brightness(1.5); height: 86px;"
                                        src="@/Content/images/modules/spaImage/logo.png" />
                                    <p class="spa-title mt-lg-5 mt-3 mb-0">Essential Vacation Experience</p>
                                    <p class="spa-subtitle mb-3">A 25 min Scalp massage + 25 min Aloe Vera Restorative
                                        Facial + one hour of our exclusive hydrotherapy area.</p>
                                    <p class="spa-price-old mb-3">$259 USD</p>
                                    <div class="col-auto mx-auto mb-3">
                                        <span class="spa-price-new border-2 border-top border-bottom px-4">$195 USD</span>
                                    </div>
                                    <button class="btn btn-vip-gold"
                                        @click="openURL('https://wa.me/+523223273663')">Book through your
                                        concierge</button>
                                    <p class="spa-content mt-2 mb-lg-3 mb-3">Book through Concierge and get this
                                        exclusive spa package for <span style="color: #DBAA4B;">only 195 USD</span></p>
                                    <p class="spa-describe mb-2 mb-lg-3">Exclusive promotion available through your concierge.Cannot
                                        be combined with other promotions, discount or resort credit.</p>
                                    <p class="spa-charges">*12.5% service charge and 16% local tax included.</p>
                                </div>
                            </div>
                        </div>
                    </div>
        </template>
    </modal>
</template>
<script>

import modal from '@/components/modal.vue';

export default {
    components: {
        modal,
    },
  methods: {
    openUrl(url) {
        window.open(url, '_blank')
    },
  },
};
</script>

<style scoped>
.custom-size-img::after {
    position: absolute;
    content: 'ONLY VIP ACCESS OFFER';
    font-weight: 500;
    padding: 0.5rem 4rem;
    color: white;
    top: calc(-1.6rem + 5rem);
    left: -4.8rem;
    transform: rotate(-45deg);
    background-color: var(--vip-gold);
}

.spa-title {
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(1.063rem, 1vw + 0.75rem, 1.5rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: 500;
    color: var(--unnamed-color-ffffff);
    font: normal normal medium;
    letter-spacing: 2.4px;
}

.spa-subtitle {
    font-family: var(--unnamed-font-family-arpona);
    font-size: clamp(0.938rem, 2vw + 0.5rem, 1.5rem);
    font-style: var(--unnamed-font-style-italic);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-dbaa4b);
    margin-bottom: 3rem;
}

.spa-price-old {
    text-decoration: line-through;
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(1.5rem, 1vw + 1.25rem, 1.75rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 2.4px;
    color: #FFFFFF;
}

.spa-price-new {
    /* font: normal normal medium 34px Gravesend Sans; */
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(2.125rem, 1vw + 1.75rem, 2.563rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 3.4px;
    color: #FFFFFF;
}

.spa-content {
    font-family: var(--unnamed-font-family-arpona);
    font-size: clamp(0.938rem, 1vw + 0.5rem, 1rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    /* font: normal normal normal 16px Arpona; */
    letter-spacing: 0px;
    color: #FFFFFF;
}

.spa-describe {
    /* font: normal normal normal 14px Arpona; */
    font-family: var(--unnamed-font-family-arpona);
    font-size: clamp(0.813rem, 1vw + 0.5rem, 0.875rem);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: 0px;
    color: #FFFFFF;
}

.spa-charges {
    font: normal normal normal 12px Arpona;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.promotion-spa {
    background-image:
        linear-gradient(180deg, #11111DE6 0%, #11111D00 100%),
        url(@/Content/images/modules/spaImage/spa-bg-lg.jpg);
    width: 100%;
    height: 621px;
    border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* filter: brightness(0.7); */
}

@media (max-width: 768px) {
    .promotion-spa {
        background-image:
            linear-gradient(180deg, #11111DE6 0%, #11111D00 100%),
            url(@/Content/images/modules/spaImage/spa-BG-sm.jpg);
        width: 100%;
        height: 720px;
        border-radius: 50px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        /* filter: brightness(0.7); */
    }
}

@media (min-width: 992px) {
    .custom-size-img::after {
        padding: 0.5rem 10rem;
        color: white;
        top: calc(-4.6rem + 10rem);
        left: -10.8rem;
        letter-spacing: 4px;
    }
}
</style>