<template>
    <footer class="position-relative bg-black">
        <div class="container-fluid custom-size">
            <div class="row w-100">
                <div class="col-12 mt-5 mb-5">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-4 justify-content-md-center text-center">
                            <img src="../Content/images/logos/tafer.svg" class="col-lg-4 col-md-4 col-6 mt-lg-3 mt-md-3 mt-3 mb-5 mx-auto my-auto" />
                        </div>
                        <div class="col-12 col-md-12 col-lg-8 d-flex ">
                            <div class="col-12 col-lg-6 mx-auto my-auto mb-5">
                                <h6 class="mb-3 ms-4 footer-navigation">
                                    <strong>{{$t('footer.navigation')}}</strong>
                                </h6>
                                <router-link v-for="route in navigationRoutes" class="footer-menu ms-4 text-decoration-none d-block mb-3"
                                    :key="route.name" :to="{ name: route.name }">
                                    <template v-if="route.id==1">
                                        {{$t('footer.nav.home')}}
                                    </template>
                                    <template v-if="route.id==2">
                                        {{$t('footer.nav.property')}}
                                    </template>
                                    <template v-if="route.id==3">
                                        {{$t('footer.nav.exclusive')}}
                                    </template>
                                    <template v-if="route.id==4">
                                        {{$t('footer.nav.rewards')}}
                                    </template>
                                    <template v-if="route.id==5">
                                        {{$t('footer.nav.residence')}}
                                    </template>
                                    <template v-if="route.id==6">
                                        {{$t('footer.nav.press')}}
                                    </template>
                                </router-link>
                            </div>
                            <div class="col-12 col-lg-6 d-none d-lg-block my-auto">
                                <router-link class="footer-menu text-decoration-none d-block mb-3" to="termsandconditions">
                                    <p class="ms-3">{{$t('footer.terms')}}</p>
                                    <p class="ms-3">{{$t('footer.privacy')}}</p>
                                </router-link>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="bg-copyright  col-12 col-md-12 col-lg-12">
                            <div class="text-center mt-3 footer-copyright">
                                <router-link class="footer-copyright text-decoration-none" to="termsandconditions">
                                    <span class="ms-3 d-md-block d-lg-none">{{$t('footer.terms')}}</span>
                                    <span class="d-md-block d-lg-none ms-3">{{$t('footer.privacy')}}</span>
                                </router-link>
                                <p>Copyright &copy; {{ new Date().getFullYear() }} VIP Access - {{$t('footer.rights')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return{
            navigationRoutes:[
                { id:1, name: "Home", label: this.$t('footer.nav.home') },
                { id:2, name: "TheProperty", label: this.$t('footer.nav.property') },
                { id:3, name: "Exclusive", label: this.$t('footer.nav.exclusive') },
                // { id:4, name: "Rewards", label: "Tafer Rewards" },
                { id:5, name: 'ResidenceClub', label: this.$t('footer.nav.residence') },
                { id:6, name: "DedicatedPress", label: this.$t('footer.nav.press') },
            ]
        }
    },
};
</script>
<style scoped>
.bg-copyright{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width:auto;
    background: #767B91 0% 0% no-repeat padding-box;

}
.footer-navigation{
    text-align: left;
    font: normal normal 600 16px/28px Arpona;
    letter-spacing: 0px;
    color: #8F6F31;
    opacity: 1;
}
.footer-copyright{
    font-family: var(--unnamed-font-family-arpona);
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    color: var(--unnamed-color-11111d);
    font-size: clamp(0.75rem, 2vw - 0.25rem, 1.125rem);
    /* font-size: 18px; */
}
.footer-menu{
    text-align: left;
    font: normal normal normal 16px/24px Arpona;
    letter-spacing: 1.6px;
    color: #767B91;
    opacity: 1;
}

.footer-menu:hover{
    color: #cc9f4c;
    border-color: #cc9f4c;
}
/* @media (max-width: 768px){
    .footer-copyright{
    font-family: var(--unnamed-font-family-arpona);
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0;
    color: var(--unnamed-color-11111d);
    font-size: 16px;
}
} */
</style>