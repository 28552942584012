<template>
  <div
    class="modal"
    :class="fade"
    :id="id"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="dialogSize" :style="dialogStyle">
      <div class="custom-modal-content" :class="contentClass">
        <slot name="modal-content">
          <div class="modal-header" :class="classHeader" v-if="!hideHeader">
            <slot name="modalHeader">
              <h5 class="modal-title">{{ headerTitle }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </slot>
          </div>
          <div class="modal-body" :class="classBody">
            <slot name="default" v-bind="{hide}" />
          </div>
          <div class="modal-footer" :class="classFooter" v-if="!hideFooter">
            <slot name="modalFooter">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" class="btn btn-primary">Understood</button>
            </slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";

export default {
  props: {
    backdrop: {
      default: true,
    },
    classFooter: String,
    classHeader: String,
    focus: {
      type: Boolean,
      default: true,
    },
    dialogStyle: String,
    id: {
      type: String,
      default: "bModal" + Math.random(),
    },
    headerTitle: String,
    size: {
      type: String,
      default: "md",
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    keyboard: {
      type: Boolean,
      default: true,
    },
    fade: {
      type: String,
      default: "fade",
    },
    fullscreenSize: {
      type: String,
      default: null,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    contentClass: String,
    classBody: String,
    autoOpen: Boolean
  },
  data() {
    return {
      modal: null,
    };
  },
  methods: {
    show() {
      this.modal.show();
    },
    toggle() {
      this.modal.toggle();
    },
    hide() {
      this.modal.hide();
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    },
    onHide(event) {
      this.$emit("hide", event);
    },
    onHidden(event) {
      this.$emit("hidden", event);
    },
    onShow(event) {
      this.$emit("show", event);
    },
    onShown(event) {
      this.$emit("shown", event);
      document.body.style.paddingRight = "0px";
    },
    dispose() {
      this.modal.dispose();
    },
  },
  computed: {
    dialogSize() {
      let dialogClass = `modal-${this.size} `;

      if (this.fullscreenSize) {
        dialogClass += `modal-fullscreen-${this.fullscreenSize}-down `;
      }

      if (this.scrollable) {
        dialogClass += "modal-dialog-scrollable ";
      }

      if (this.centered) {
        dialogClass += "modal-dialog-centered";
      }

      return dialogClass;
    },
  },
  mounted() {
    this.modal = Modal.getOrCreateInstance(this.$el);

    if (!this.modal) {
      this.modal = new Modal(this.$el, {
        backdrop: this.backdrop,
        keyboard: this.keyboard,
        focus: this.focus,
      });
    }

    this.$el.addEventListener("show.bs.modal", this.onShow);
    this.$el.addEventListener("shown.bs.modal", this.onShown);
    this.$el.addEventListener("hide.bs.modal", this.onHide);
    this.$el.addEventListener("hidden.bs.modal", this.onHide);

    this.$emit("init", {
      show: this.show,
      toggle: this.toggle,
      hide: this.hide,
    });
    if(this.autoOpen){
      this.show();
    }
  },
  beforeUnmount() {
    this.hide();
    this.dispose();
    this.$el.removeEventListener("show.bs.modal", this.onShow);
    this.$el.removeEventListener("shown.bs.modal", this.onShown);
    this.$el.removeEventListener("hide.bs.modal", this.onHide);
    this.$el.removeEventListener("hidden.bs.modal", this.onHide);
  },
};
</script>

<style scoped>
.custom-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
}
</style>
