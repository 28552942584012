export const myLiveChatToggle = async({commit, state}) => {
    
    if(state.myLiveChat.clicked)return;

    let chatStatus = {
        clicked: true,
        hidden: state.myLiveChat.hidden
    };

    commit('myLiveChatClicked', chatStatus);

    await loadMyLiveChat({commit, state});

    chatStatus.clicked = false,
    chatStatus.hidden = !chatStatus.hidden;

    commit('myLiveChatClicked', chatStatus);

    MyLiveChat_OpenDialog();

}

export const loadMyLiveChat = ({commit, state}) =>{

    return new Promise((resolve, reject) => {
        let timerId = 0;

        if(state.myLiveChat.loaded){
            resolve();
            return;
        }
    
        setTimeout(() => {
            if(timerId){
                clearInterval(timerId);
            }

            if(typeof(MyLiveChat_OpenDialog) == undefined){
                reject();
            }
        }, 10000)

        timerId = setInterval(() => {
            console.warn('loading...');
            if (typeof(MyLiveChat_OpenDialog) != undefined) {
                console.info('MyLiveChat Loaded');
                clearInterval(timerId);
                commit('onLoadMyLiveChat');
                resolve();
            }
        }, 100)
    })
}