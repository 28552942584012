<template>
    <modal hide-footer hideHeader centered autoOpen classBody="p-0" contentClass="overflow-hidden"
        dialogStyle="max-width:686px">
        <template #default="{hide}">
            <div class="bg-banner d-flex flex-column">
                <button type="button" class="btn-close btn-close-white position-absolute" aria-label="Close" @click="hide"
                    style="top:30px;right: 30px; transform: scale(1.8); box-shadow: none !important;">
                </button>
                <div class="col-12">
                    <div class="col-md-8 text-center mx-auto">
                        <img src="../Content/images/session/cam.svg" class="img-fluid mt-5 mb-4" />
                    <br>
                    <div class="content-text ms-3 me-3" v-html="$t('banners.session.content')">
                    </div>
                    </div>
                    <div class="footer-content col-md-8 mx-auto text-center ">
                        <img @click="openUrl('https://wa.me/+523223273663')" style="cursor: pointer;" src="@/Content/images/session/btnSession.png" />
                        <!-- <p class="disclaimer text-white">
                            {{$t('banner.disclaimer')}}
                        </p> -->
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '../components/modal.vue'

export default {
    components:{
        modal,
    },
    methods:{
        openUrl(url) {
            open(url, '_blank')
        },
    }
}
</script>
<style scoped>
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    outline: 0;
}
.bg-banner{
    position:relative;
    background-image: 
    linear-gradient(180deg, rgba(17,17,29,1) 0%, rgba(246,246,246,0) 90%),
    url('@/Content/images/session/bg.png');
    height: 611px;
    background-position: center;
    background-repeat: no-repeat!important ;
    background-size: cover;
    border-radius: 50px;
}
.disclaimer{
    font-size:12px;
}
.footer-content{
    position: absolute;
    bottom: 0.50rem!important;
    left: 0;
    right: 0;
    width: 65%;
}
.content-text{
    font-family: var(--unnamed-font-family-arpona);
    font-style: var(--unnamed-font-style-normal) normal 200;
    font-size: clamp(1rem, 1vw + 0.75rem, 1.125rem);
    color: var(--unnamed-color-ffffff);
    letter-spacing: var(--unnamed-character-spacing-0) 0px;
    /* line-height: 2.2; */
}
@media (max-width:616px) {
    .footer-content{
    position: absolute;
    bottom: 0.50rem!important;
    left: 0;
    right: 0;
    width: 90%;
    }

}
</style>