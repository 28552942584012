<template>
    <modal hide-footer hideHeader centered autoOpen classBody="p-0" contentClass="overflow-hidden"
        dialogStyle="max-width:686px">
        <template #default="{hide}">
            <div class="bg-banner d-flex flex-column">
                <button type="button" class="btn-close btn-close-white position-absolute" aria-label="Close"
                    @click="hide" style="top:30px;right: 30px; transform: scale(1.5); box-shadow: none !important;">
                </button>
                <div class="row h-75">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="col-md-9 text-center mx-auto">
                                    <img src="../Content/images/karuma_ban/percent.svg"
                                        class="img-fluid mt-4 mb-3 col-lg-2 col-md-2" />
                                    <br>
                                    <p class="title text-capitalize"
                                        style="font-size: 70px !important;font-weight: 800 !important;">10%</p>
                                    <p class="subtitle text-capitalize mb-3">Discount</p>
                                    <div class="banner-content-text ms-3 me-3"
                                        v-html="$t('banners.karumabanner.content')">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-10 col-12">
                                <div class="row">
                                    <div class="col-lg-6 col-4 text-end position-relative" style="right: -27px;">
                                        <img src="@/Content/images/karuma_ban/karuma.png" style="width: 117px" />
                                    </div>
                                    <div class="col-lg-6 col-7 mt-3 position-relative" style="left:10px">
                                        <p class="bannertitle ms-2 mb-2  text-uppercase">
                                            {{ $t('banners.karumabanner.restaurant') }}
                                        </p>
                                        <p class="bannersubtitle ms-2">{{ $t('banners.karumabanner.quote') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-10 col-12">
                    <div class="row">
                        <div class="col-lg-6 col-4 text-end position-relative" style="right: -27px;">
                            <img src="@/Content/images/karuma_ban/karuma.png" style="width: 117px" />
                        </div>
                        <div class="col-lg-6 col-7 mt-3 position-relative" style="left:10px">
                            <p class="bannertitle ms-2 mb-2  text-uppercase">{{$t('banners.karumabanner.restaurant')}}
                            </p>
                            <p class="bannersubtitle ms-2">{{$t('banners.karumabanner.quote')}}</p>
                        </div>
                    </div>
                </div> -->
                <div class="col-12 mx-auto my-auto text-center">
                    <button class="btn btn-vip-gold" @click="openURL('https://wa.me/+523223273663')">
                        <span class="btn-vip-text">Book Now</span>
                    </button>
                </div>
            </div>
        </template>
    </modal>
</template>
<script>
import modal from '../components/modal.vue'

export default{
    components:{
        modal,
    },
    methods:{
        openURL(url) {
            window.open(url, '_blank')
        },
    }
}
</script>
<style scoped>
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.banner-content-text {
    font-family: var(--unnamed-font-family-arpona);
    font-style: var(--unnamed-font-style-normal) normal 200;
    font-size: clamp(0.875rem, 2vw - 0.25rem, 1rem);
    color: var(--unnamed-color-ffffff);
    letter-spacing: var(--unnamed-character-spacing-0) 0px;
}

.bg-karuma {
    position: relative;
    background-image:
        url(@/Content/images/karuma_ban/karuma.png);
    max-width: 100%;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat !important;
    background-size: cover;
}

.bannertitle{
    font-family: normal normal normal var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(1.125rem, 1vw + 0.75rem, 1.25rem);
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
}

.bannersubtitle{
    font-family: italic normal normal  var(--unnamed-font-family-arpona);
    letter-spacing: 0px;
    font-size: clamp(0.75rem, 1vw + 0.5rem, 0.813rem);
    color: white;
}

@media (max-width: 768px) {
    .bg-banner{
        position:relative;
        background-image:
        linear-gradient(0deg, rgba(17,17,29,0.8715861344537815) 0%, rgba(17,17,29,0.4374124649859944) 100%),
        url('@/Content/images/karuma_ban/bg_768.jpg');
        height: 611px;
        background-position: center;
        background-repeat: no-repeat!important ;
        background-size: cover;
        border-radius: 45px;
    }
}
@media (min-width: 769px) {
    .bg-banner{
        position:relative;
        background-image:
        linear-gradient(0deg, rgba(17,17,29,0.8715861344537815) 0%, rgba(17,17,29,0.4374124649859944) 100%),
        url('@/Content/images/karuma_ban/bg_full.jpg');
        height: 611px;
        background-position: center;
        background-repeat: no-repeat!important ;
        background-size: cover;
        border-radius: 45px;
    }
}


</style>