<template>
    <modal id="updateLauncher" hide-footer hideHeader centered autoOpen classBody="p-0" contentClass="overflow-hidden" dialogStyle="max-width:358px">
        <template #default="{hide}">
            <div class="bg-banner d-flex flex-column">
                <div class="col-12">
                    <div class="ms-3 me-3 text-center">
                        <Vue3Lottie :animationData="rocket" :height="342" :width="227" />
                        <p class="alert-title text-capitalize mb-4">{{$t('update.title')}}</p>
                        <p class="alert-subtitle">{{$t('update.content')}}</p>
                    </div>
                </div>
                <div class="footer-content">
                    <div class="ms-3 me-3 text-center">
                        <slot name="updateButton" v-bind="{hide}">
                            <!-- <button @click="uiHide(hide)" class="mb-3 btn btn-vip-gold">
                                <span class="btn-vip-text">{{$t('btns.updateNow')}}</span>
                            </button> -->
                        </slot>
                        <slot name="submitButton">
                            <button class="mb-3 btn btn-submit" @click="hide">
                                <span class="btn-vip-text">{{$t('btns.anotherTime')}}</span>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '../components/modal.vue'
import rocket from '@/Content/images/lotties/rocket.json'
// import update from '@/mixins/update'

export default {
    components:{
        modal,
    },
    data(){
        return{
            rocket,
        }
    },
    // mixins:[update],
    // methods:{
    //     uiHide(e){
    //         e();
    //         this.refreshApp();
    //     }
    // }
}
</script>

<style>
.bg-banner{
    position: relative;
    height: 650px;
    background-color:#0F0F17 ;
    background-position: center;
    background-repeat: no-repeat!important ;
    background-size: cover;
    border-radius: 50px;
}
.modal-content{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
}

.alert-title {
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-size: clamp(1rem, 1vw + 1rem, 1.5rem)!important;
    font-style: var(--unnamed-font-style-normal);
    font-weight: 500;
    line-height: var(--unnamed-line-spacing-43);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
}

.alert-subtitle {
    font-family: var(--unnamed-font-family-arpona);
    font-size: clamp(1.125rem, 1vw + 0.80rem, 1rem)!important;
    font-style: var(--unnamed-font-style-italic);
    font-weight: var(--unnamed-font-weight-normal);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: white!important;;
    margin-bottom: 3rem;
}

.footer-content{
    position: absolute;
    bottom: 0.50rem!important;
    left: 0;
    right: 0;
    width: 100%;
}

/* .btn-vip-gold {
    opacity: 1;
    background: var(--vip-gold) 0% 0% no-repeat padding-box;
    border-radius: 2rem;
    line-height: 2;
    padding-left: 2rem;
    padding-right: 2rem;
}

.btn:first-child:hover, 
:not(.btn-check)+.btn:hover {
  background-color: #cc9f4c;
  border-color: #cc9f4c;
} */

.btn-submit {
    background: #767B91 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000078;
    opacity: 1;
    line-height: 2;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 2rem;
}

.btn.btn-submit:hover{
  background-color: #3f4146 !important;
  border-color: #3f4146;
}
</style>