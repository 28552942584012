<template>
  <headerVip name="header" class="position-absolute w-100" style="top:0; z-index: 1040;" />
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="in-out">
      <component :is="Component" id="mainView" />
    </transition>
  </router-view>
  <launcher v-if="updateExists">
    <template #updateButton="{ hide }">
      <button @click="uiHide(hide)" class="mb-3 btn btn-update">
        <span class="btn-vip-text">{{ $t('btns.updateNow') }}</span>
      </button>
    </template>
  </launcher>
  <footerVip />
  <component :is="banners[currentBanner]" />
  <widget-contact />
</template>

<script>
import { mapActions } from 'vuex';

import headerVip from './layouts/header.vue'
import footerVip from './layouts/footer.vue';
import WidgetContact from './components/WidgetContact.vue'
import launcher from './components/updateAlert.vue'
import update from '@/mixins/update'
import { Tooltip } from 'bootstrap';
import welcome from '@/components/welcomeBanner.vue'
import karuma from '@/components/karumabanner.vue'
import session from '@/components/sessionBanner.vue'
import spa from '@/components/banners/spaPromotion.vue'
// import {welcome,karuma,session} from './components/banners'
export default {
  name: "app",
  components: {
    headerVip,
    footerVip,
    WidgetContact,
    launcher,
    welcome,
    karuma,
    session,
    spa
  },
  data() {
    return {
      language: '',
      collapse: null,
      banners: [
        welcome,
        spa,
        karuma,
        session
      ],
      currentBanner: 0
    }
  },
  mixins: [update],
  methods: {
    ...mapActions('user', ['loadMyLiveChat']),
    uiHide(e) {
      e();
      this.refreshApp();
    }
  },
  async mounted() {
    await this.loadMyLiveChat();
  },
  mounted() {
    this.$router.afterEach((to) => {
      //if(to.name != "Home"){
      let currentBanner = this.currentBanner;
        currentBanner++;

        if (currentBanner == this.banners.length) currentBanner = 1;

        this.currentBanner = currentBanner;
        this.localStorage.setItem('currentBanner', currentBanner);
      //}
    })

    document.addEventListener('DOMContentLoaded', (e) => {
      const element = document.getElementById("contactMethodsContainer");
      const largeTooltip = new Tooltip(element, {
        delay: { show: 500, hide: 1000 }
      });

      const bodyElement = document.getElementsByName('header');

      // console.log(bodyElement);
    })
    this.language = navigator.language.includes('es') ? 'es-MX' : 'en-US';
    this.$i18n.locale = this.language;

    this.currentBanner = localStorage.getItem('currentBanner') || -1;
  },
}
</script>

<style scoped>
.btn-update {
  opacity: 1;
  background: var(--vip-gold) 0% 0% no-repeat padding-box;
  border-radius: 2rem;
  line-height: 2;
  padding-left: 2rem;
  padding-right: 2rem;
}

.btn:first-child:hover,
:not(.btn-check)+.btn:hover {
  background-color: #cc9f4c;
  border-color: #cc9f4c;
}

.tooltip-head {
  color: #fff;
  background: #000;
  padding: 10px 10px 5px;
  border-radius: 4px 4px 0 0;
  text-align: center;
  margin-bottom: -2px;
  /* Hide default tooltip rounded corner from top */
}

.tooltip-head h3 {
  margin: 0;
  font-size: 18px;
}

.tooltip-head i {
  font-size: 22px;
  vertical-align: bottom;
}
</style>
