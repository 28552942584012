// import magazineModule from '../pages/magazine/store';
// import activitiesModule from '../pages/activities/store';
// import benefitsModule from '../pages/benefits/store';
// import directoryModule from '../pages/directory/store';
// import eventsModule from '../pages/events/store';
import userModule from './user';

export default {
    // magazine: magazineModule,
    // activities: activitiesModule,
    // benefits: benefitsModule,
    // directory: directoryModule,
    // events: eventsModule,
    user: userModule,
}