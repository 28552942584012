import { createWebHistory, createRouter } from "vue-router";
import { nextTick } from "vue";

const routes=[
    {
        path:'',
        name:'Home',
        component:()=> import('../pages/home/home.vue'),
        alias:'/:pathMatch(.*)*',
        meta:{
            pageName:''
        }
    },
    {
        path:'/theproperty',
        name:'TheProperty',
        component:()=> import('../pages/theproperty/theproperty.vue'),
        meta:{
            pageName:'The Property'
        }
    },
    {
        path:'/exclusive',
        name:'Exclusive',
        component:()=> import('../pages/exclusive/exclusive.vue'),
        meta:{
            pageName:'Exclusive Experiences'
        }
    },
    {
        path:'/rewards',
        name:'Rewards',
        component:()=> import('../pages/rewards/rewards.vue'),
        meta:{
            pageName:'Tafer Rewards'
        }
    },
    {
        path:'/residenceClub',
        name:'ResidenceClub',
        component:()=> import('../pages/residence/residence.vue'),
        meta:{
            pageName:'Residence Club'
        }
    },
    {
        path:'/dedicatedPress',
        name:'DedicatedPress',
        component:()=> import('../pages/press/press.vue'),
        meta:{
            pageName:'Dedicated Press'
        }
    },
    {
        path:'/termsandconditions',
        name:'terms',
        component:()=>import('../pages/terms/terms.vue'),
        meta:{
            pageName:'Terms and Conditions'
        }
    }
]

const scrollBehavior = async (to, _, savedPosition) => {

    if (to.hash) {
        //Es necesario retornar una promesa, de lo contrario ignorara el offset que se le añadio en la propiedad top         
        return new Promise(resolve => {
            setTimeout(() => {
                resolve({ el: to.hash, top: 150, behavior: 'smooth' })
            }, 100);
        })
    }
    else if (savedPosition) {
        return savedPosition;
    } else {
        return { left: 0, top: 0, behavior: 'smooth' }
    }
};

const router=createRouter({
    history:createWebHistory(),
    routes,
    scrollBehavior,
})

const setPageTitle = (to) => {
    let pageTitle = 'VIP Access';

    if (to.meta.pageName) {
        pageTitle += ` - ${to.meta.pageName}`;
    }

    nextTick(() => {
        document.title = pageTitle;
    });
}

router.beforeEach((to, _, next) => {

    if (to.fullPath.indexOf('/#/') > -1) {
        next(to.fullPath.replace('/#/', '/'));
        return;
    }
    next();
})

router.afterEach(to => {
    setPageTitle(to);
})

export default router