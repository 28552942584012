<template>
    <!-- <div style="width:100px; margin-top: -29.99px;"> -->
    <div style="width:50px; margin-top: -2.99px; margin-left: -8px;">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
        y="0px" viewBox="0 0 146.1 146.1" style="enable-background:new 0 0 146.1 146.1;" xml:space="preserve">
        <g>
            <path id="Trazado_520" class="st0" d="M123.4,69L89.9,73l33.5,4l22.7-4L123.4,69z" />
            <path id="Trazado_521" class="st0" d="M121.3,63.4l-7.9-12.6L98.8,66.7L121.3,63.4z" />
            <path id="Trazado_522" class="st0" d="M98.8,79.4l14.6,15.9l7.9-12.6L98.8,79.4z" />
            <path id="Trazado_523" class="st0"
                d="M99.7,34.4L81.3,51.7l15.9-2.8l-2.8,15.9l17.3-18.4l10.3-1.8L108,38l-6.5-13.9L99.7,34.4z" />
            <path id="Trazado_524" class="st0" d="M22.7,69L0,73l22.7,4l33.5-4L22.7,69z" />
            <path id="Trazado_525" class="st0" d="M47.3,79.4l-22.5,3.3l7.9,12.6L47.3,79.4z" />
            <path id="Trazado_526" class="st0" d="M47.3,66.7L32.7,50.7l-7.9,12.6L47.3,66.7z" />
            <path id="Trazado_527" class="st0"
                d="M46.3,111.7l18.4-17.3l-15.9,2.8l2.8-15.9L34.4,99.7l-10.3,1.8L38,108l6.5,13.9L46.3,111.7z" />
            <path id="Trazado_528" class="st0" d="M77.1,22.7L73,0L69,22.7L73,56.2L77.1,22.7z" />
            <path id="Trazado_529" class="st0" d="M63.4,24.8l-12.6,7.9l15.9,14.6L63.4,24.8z" />
            <path id="Trazado_530" class="st0" d="M82.7,24.8l-3.3,22.5l15.9-14.6L82.7,24.8z" />
            <path id="Trazado_531" class="st0"
                d="M34.4,46.3l17.3,18.4l-2.8-15.9l15.9,2.8L46.3,34.4l-1.8-10.3L38,38l-13.9,6.5L34.4,46.3z" />
            <path id="Trazado_532" class="st0" d="M69,123.4l4.1,22.7l4.1-22.7L73,89.9L69,123.4z" />
            <path id="Trazado_533" class="st0" d="M82.7,121.3l12.6-7.9L79.4,98.8L82.7,121.3z" />
            <path id="Trazado_534" class="st0" d="M63.4,121.3l3.3-22.5l-15.9,14.6L63.4,121.3z" />
            <path id="Trazado_535" class="st0"
                d="M111.7,99.7L94.4,81.3l2.8,15.9l-15.9-2.8l18.4,17.3l1.8,10.3L108,108l13.9-6.5L111.7,99.7z" />
        </g>
    </svg>
    </div>
    
</template>
<style scoped>
* {
    /* margin: -42px;
    padding: 33px; */
    /* box-sizing: border-box; */
    margin: 0px;
    padding: 0px;
}

body {
    text-align: center;
}
.st0{fill:#11111D;}
</style>