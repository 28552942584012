export default{
    data(){
        return{
            refreshing:false,
            registration:null,
            updateExists:false,
        }
    },
    created(){
        document.addEventListener('swUpdated', this.updateAvailable,{once:true})

        navigator.serviceWorker.addEventListener('controllerchange',(e)=>{
            if(this.refreshing) return 
            this.refreshing=true
            console.log('Controler con cambio... Refrescamiento: '+this.refreshing);
            window.location.reload();
            
        });
    },
    methods:{
        updateAvailable(event){
            console.log('Evento de Actualizacion disponible...');
            this.registration=event.detail.waiting;
            this.updateExists=true;
            console.log('Existe actualizacion:'+this.updateExists,this.registration);
            
        },
        refreshApp(){
            let SkWaiting;
            this.updateExists=false;
            console.log(this.registration);
            if(this.registration!=null)
            {
                SkWaiting=this.registration;
                return SkWaiting.postMessage({ type: 'SKIP_WAITING' });
            }
            // if(!this.registration || !this.registration.waiting) return this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // console.log('Refrescamiento: '+this.refreshing,this.registration,'Existe Actualizacion: '+this.updateExists);

        }
        
    }
}