<template>
    <transition name="slide-options" appear>
        <div class="position-fixed text-center button-style shadow p-1" style="bottom:20px;right:20px;z-index: 999999;"
            id="contactMethodsContainer" v-show="showCustom" :show-options="showContactOptions"
            data-bs-toggle="tooltip" :title="`${$t('conciergeWidget.customer')}`">
            <transition-group name="slide-options" appear>
                <template v-if="showContactOptions">
                    <component :is="contact.tag"
                    :href="contact.href" 
                    class="btn mb-2 d-block rounded-circle p-2 bi fs-4 btn-contact"
                    @click="contact.action()" 
                    v-for="contact in contactMethods" 
                    :key="contact.name"
                    :class="contact.icon" 
                    :target="contact.target"
                    ></component>
                </template>
            </transition-group>

            <button  class="btn btn-open-contact button-style shadow"
                style="margin:-0.5rem -0.5rem 0rem; height: 3.125rem;" 
                :show-options="showContactOptions"
                >
                <concierge/>
            </button>
        </div>
    </transition>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import concierge from '@/components/conciergeIcon.vue'

let btnMyLiveChatHide = null,
    btnMyLiveChatClose = null;

export default {
    components:{
        concierge
    },
    data() {
        return {
            contactMethods: [
                { name: 'Whatsapp', action: () => 0, href: 'https://wa.me/+523223273663', icon: 'bi-whatsapp', target: '_blank', tag: 'a' },
                { name: 'Phone', action: () => 0, href: 'tel:3223273663', icon: 'bi-telephone', tag: 'a' },
                { name: 'My Live Chat', action: this.openMyLiveChat, icon: 'bi-chat', tag: 'button', type: 'button' },
            ],
            showContactOptions: false,
            showCustom: true,
        }
    },
    methods: {
        ...mapActions('user', ['myLiveChatToggle']),
        ...mapMutations('user', ['myLiveChatClicked']),
        async openMyLiveChat() {
            await this.myLiveChatToggle();
            this.showCustom = false;
            this.showContactOptions = false;
            
            if (!btnMyLiveChatClose) {
                btnMyLiveChatClose = document.querySelector('.mylivechat_closebtn');
                btnMyLiveChatClose.addEventListener('click', this.onCloseMyLiveChat);
            }
        },
        clickAnotherElement({ target }) {
            //Se revisa si se le dio click al elemento
            const btnToggleContact = this.$el.querySelector('.btn-open-contact');
            if (btnToggleContact.contains(target) || btnToggleContact == target) this.showContactOptions = !this.showContactOptions;
            if (!(this.$el == target || this.$el.contains(target))) this.showContactOptions = false;
        },
        onCloseMyLiveChat() {
            this.showCustom = true;
            this.myLiveChatClicked({clicked: false, hidden: true});
        },
    },
    computed: {
        ...mapGetters('user', ['myLiveChatLoded', 'myLiveChatHidden'])
    },
    watch: {
        myLiveChatLoded(val) {
            if (val) {
                setTimeout(() => {
                    btnMyLiveChatHide = document.querySelector('.mylivechat_sprite');

                    btnMyLiveChatHide.addEventListener('click', this.onCloseMyLiveChat);
                }, 1000)

            }
        },
        myLiveChatHidden(val){
            this.showCustom = val;
        }
    },
    async mounted() {
        document.addEventListener('click', this.clickAnotherElement);
    },
    unmounted() {
        document.removeEventListener('click', this.clickAnotherElement)
        btnMyLiveChatHide.removeEventListener('click', this.onCloseMyLiveChat);

        if (btnMyLiveChatClose) {
            btnMyLiveChatClose.removeEventListener('click', this.onCloseMyLiveChat);
        }
    }
}
</script>
<style>
#contactMethodsContainer>a {
    position: relative;
}

#contactMethodsContainer>a:before,
#contactMethodsContainer>button:before {
    display: block;
}

/* .btn-open-contact {
    transition: all 0.5s ease;
    background-color: var(--vip-gold);
    color: white;
} */

.btn-open-contact:hover,
.btn-open-contact[show-options="true"] {
    opacity: 1.25;
    color: var(--som-rio-grande);
}

.btn-contact {
    background: var(--som-sugar-cane);
    transition: ease 0.25s all;
}

.btn-contact.bi-whatsapp {
    color: #198754;
}

.btn-contact.bi-telephone {
    color: var(--som-pink);
}

.btn-contact.bi-chat {
    color: #1773fc
}

.btn-contact.bi-whatsapp:hover {
    background: #198754;
    color: var(--som-sugar-cane);
}

.btn-contact.bi-telephone:hover {
    background: var(--som-pink);
    color: var(--som-sugar-cane);
}

.btn-contact.bi-chat:hover {
    background: #1773fc;
    color: var(--som-sugar-cane);
}

#contactMethodsContainer {
    width: 50px;
    background-color: var(--vip-gold);
    transition: ease 0.5s all;
}

#contactMethodsContainer[show-options="true"] {
    background-color: var(--som-silver);
    height: auto;
}

.slide-options-enter-active,
.slide-options-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-options-enter-from,
.slide-options-leave-to {
    transform: translateY(20px);
    opacity: 0;
    height: auto;
}

.button-style{
    border-radius: 50px 50px 5px;
}


</style>