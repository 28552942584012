import { createI18n } from 'vue-i18n'

const messages={
    'en-US':{
        header:{
            nav:{
                home:'Home',
                property:'The Property',
                exclusive:'Exclusive Experiences',
                rewards:'Tafer Rewards',
                residence:'Residence Club',
                press:'Dedicated Press'
            },
        },
        footer:{
            nav:{
                home:'Home',
                property:'The Property',
                exclusive:'Exclusive Experiences',
                rewards:'Tafer Rewards',
                residence:'Residence Club',
                press:'Dedicated Press'
            },
            navigation:'Navigation',
            terms:'Terms & Conditions',
            privacy:'Privacy Policy',
            rights:'All rights reserved'
        },
        home:{
            welcome:'Welcome To',
            the:'The&nbsp;',
            vipAccess:'VIP Access',
            messageMain:'Unlock extraordinary experiences.',
            exclusive:{
                title:'Exclusive Experiences',
                subtitle:'Explore Our Authentic And Unique Tafer Resorts Experiences',
                iconic:'5 iconic spots for instagram captions.',
                iconic2:'Upgrade the session with rooftop views and champagne.',
                local:'10.2 The Local Channel',
                tailor:'Tailor Made Experience',
                cocktail:'Cocktail Night & Pairing Dinner'
            },
            property:{
                title:'The Property',
                subtitle:'Discover the most extraordinary vacation experiences',
                content:"The property offers you one of Mexico's most extraordinary vacation experiences."+
                        "Nestled along the exclusive southern coastline of Puerto Vallarta, host the first and only AAA Five Diamond rated "+
                        "resort in Puerto Vallarta the exclusive Hotel Mousai and Garza Blanca Preserve Resort & Spa Considered to be one of "+
                        "the best hotels in Puerto Vallarta, our luxury beach resort delivers a sophisticated array of hotel amenities on in-room "+
                        "service to each and every guest.",
                heat:'Outdoor swimming pools with jacuzzis & Pool Concierge.',
                white:'White sand beach',
                hiking:'Hiking Trails in lush tropical terrain',
                gourmet:'Gourmet on-site restaurants',
                world:'World-class Spa',
                kids:'Kids Club',
                oceanView:'Oceanview gym & outdoor tennis court',
                more:'And More'
            },
            residence:{
                title:'Residence Club',
                subtitle:'Puerto Vallarta',
                content:"TAFER Residence Club will take your vacations to a whole new level of perfection. Harmonizing the luxuries of a five star resort "+
                        "with the exclusivity and autonomy of vacation ownership, this magnificent Residence Club is poised with experienced staff to ensure "+
                        "your requeriments, itinerary and individual preferences are met flawlessly and with grace.",
            },
            press:{
                title:'Dedicated Press',
                subtitle:'Important news for important people'
            },
            concierges:{
                title:'Thank you for contacting us!',
                content: 'Our concierge team is ready to assist you. You will be redirected to our concierge chat shortly.',
                schedule:'Contact your online concierge from 9 am to 5 pm'
            }
        },
        property:{
            the:'The',
            property:'Property',
            garza:{
                title:'Garza Blanca Preserve Resort & Spa',
                subtitle:'Vacation Your Way, With All-Inclusive And Room Only Options',
                paragraph1:"Garza Blanca Preserve Resort & Spa in Puerto Vallarta offers you one of Mexico’s most extraordinary vacation experiences. Nestled along the exclusive southern coastline of Puerto Vallarta, you’ll discover 85 acres of lush tropical jungle cascading down to a white sand beach with crystalline waters.",
                paragraph2:"Come and lounge by our infinity pools, linger over creative cuisine, and tap into deep relaxation in our world-class spa. Take a guided hike through the Sierra Madre mountains or paddle a kayak along the bay. Here, amidst the spellbinding scenery, you’ll enjoy an unparalleled experience of modern design and vibrant, attentive service.",
                
            },
            mousai:{
                title:'Hotel Mousai',
                subtitle:'Puerto Vallarta',
                paragraph1:"Hotel Mousai in Puerto Vallarta sets the pace for a new generation of designer hotels for guests aged over 16 years old, where fresh flirtatious aesthetics and cutting-edge comfort will inspire you to live sensationally. An eclectic yet precise fusion of imagination, contemporary design and inventive functionality generates spaces of artistic magnitude and dazzling personality – a gallery of experiences and creativity.",
                paragraph2:""
            },
            propertyMap:'Property Map',
            culinaryOffer: {
                title: 'Culinary Offer',
                subtitle: 'Gourmet Culinary',
                content: 'Immerse yourself in an extraordinary culinary experience. Seduce your senses with flavors and aromas from our many dining options, and enjoy one deliciously unforgettable meal after the next. Relax and partake surrounded in luxurious ambiance, accompanied by personalized service.',
                allInclusive:'Culinary All-Inclusive',
                gourmet:'Gourmet Culinary All-Inclusive',
                premium:'Premium Culinary Experience',
                dressCode:{
                    title:'DINNER DRESS CODE',
                    content:'We kindly request that you follow our resort evening dress code for ladies and gentlemen!',
                    avoid:'Please avoid:',
                    contentAvoid:"All T-Shirts &#8739; Bathing Suits / Cover-Ups &#8739; Flip-Flops &#8739; Pool & Beach Footwear &#8739; Sportswear and Sneakers "
                }
            },
            aquazul: {
                title: 'Aquazul',
                subtitle: 'Snacks and Italian Restaurant',
                comment: '',
                paragraph1: "Aquazul at Garza Blanca Preserve takes poolside favorites like burgers, salads, guacamole and ceviche and gives them a sophisticated twist. Enjoy Views of the infinity pool, soft sands and crystalline waters.",
                paragraph2: "Aquazul plays with the typical poolside snacks like burgers, salads, guacamole and ceviche giving them a sophisticated twist that reminds you where you are - paradise.",
                paragraph3: "With such an extensive and versatile menu, lunch and snack time become a delightful pleasure for you and your whole family.",
                content:'<p class="content-modal-text">'+
                "Aquazul at Garza Blanca Preserve takes poolside favorites like burgers, salads, guacamole and ceviche and gives them a sophisticated twist. Enjoy Views of the infinity pool, soft sands and crystalline waters."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Aquazul plays with the typical poolside snacks like burgers, salads, guacamole and ceviche giving them a sophisticated twist that reminds you where you are - paradise."
                +'</p>'+
                '<p class="content-modal-text">'+
                "With such an extensive and versatile menu, lunch and snack time become a delightful pleasure for you and your whole family."
                +'</p>'
            },
            terrace: {
                title: 'The Terrace',
                subtitle: 'American and mexican breakfast',
                comment: 'MOUSAI ONLY',
                paragraph1: "As the sun rises over the Sierra Madre Mountains in Puerto Vallarta, an eclectic breakfast awaits to inspire you towards a brand new day of unique Hotel Mousai adventures.",
                content:'<p class="content-modal-text">'+
                "As the sun rises over the Sierra Madre Mountains in Puerto Vallarta, an eclectic breakfast awaits to inspire you towards a brand new day of unique Hotel Mousai adventures."
                +'</p>'
            },
            rooftop: {
                title: 'Rooftop',
                subtitle: 'Elevated Cuisine, High-Flying Taste',
                comment: 'MOUSAI ONLY',
                paragraph1: "One of the best rooftop bars in Puerto Vallarta, by day The Rooftop is the perfect place to chill out by the sensational infinity pool and savor gourmet bites. By night, it is the place to go for a magical encounter under the stars. At any time of day or night, The Rooftop is the height of trendy luxury with relaxing spaces, comfy lounge chairs, and legendary libations.",
                content:'<p class="content-modal-text">'+
                "One of the best rooftop bars in Puerto Vallarta, by day The Rooftop is the perfect place to chill out by the sensational infinity pool and savor gourmet bites. By night, it is the place to go for a magical encounter under the stars. At any time of day or night, The Rooftop is the height of trendy luxury with relaxing spaces, comfy lounge chairs, and legendary libations."
                +'</p>'
            },
            roomService: {
                title: 'Room Service',
                subtitle: '24 Hours',
                comment: 'MOUSAI ONLY',
                paragraph1: "iPad technology can be found in each and every suite at Hotel Mousai, which can be used to keep you up-to-date with the services available at the 5 Diamond hotel the perfect tool for you to browse the 24 hour room service menu which includes dishes for breakfast and dinner as well as wine and cocktails.",
                content:'<p class="content-modal-text">'+
                "iPad technology can be found in each and every suite at Hotel Mousai, which can be used to keep you up-to-date with the services available at the 5 Diamond hotel the perfect tool for you to browse the 24 hour room service menu which includes dishes for breakfast and dinner as well as wine and cocktails."
                +'</p>'
            },
            blancaBlue: {
                title: 'Blanca Blue',
                subtitle: 'Inovative Mexican Cuisine',
                comment: '',
                paragraph1: "The best Mexican cuisine in Puerto Vallarta, only at our oceanfront restaurant: Blanca Blue. Discover iconic flavors through innovative gourmet dishes.",
                paragraph2: "The menu provides inspiration for the palate, a marriage of ultra fine dining, iconic Mexican flavors and innovative culinary techniques.",
                paragraph3: "You will enjoy the results of traditional culinary methods used to prepare modern ingredients. Diners will find all of those elements we love most about Mexican food turned into inspiring works of art that ignite the senses and the emotions on a taste odyssey.",
                content:'<p class="content-modal-text">'+
                "The best Mexican cuisine in Puerto Vallarta, only at our oceanfront restaurant: Blanca Blue. Discover iconic flavors through innovative gourmet dishes."
                +'</p>'+
                '<p class="content-modal-text">'+
                "The menu provides inspiration for the palate, a marriage of ultra fine dining, iconic Mexican flavors and innovative culinary techniques."
                +'</p>'+
                '<p class="content-modal-text">'+
                "You will enjoy the results of traditional culinary methods used to prepare modern ingredients. Diners will find all of those elements we love most about Mexican food turned into inspiring works of art that ignite the senses and the emotions on a taste odyssey."
                +'</p>'
            },
            bocados: {
                title: 'Bocados Steak House',
                subtitle: 'Modern Stakhouse',
                comment: '',
                paragraph1: "A traditional steakhouse perched on the tropical mountainside with ocean views. Come with an appetite for everything from mussels that transport you to the Sorrento Coast of Italy, to the melt-in-your-mouth dry-aged steaks, exciting array of vegetarian goodness, and decadent desserts.",
                paragraph2: "The concept behind BocaDos Steak House is one dedicated to providing exquisite choices, high quality and dreamy satisfaction. Dishes can be shared for a more casual experience, combined with fresh salads and vegetable dishes or you may opt for a full course meal, pairing your dining with fine wine, craft beer or refreshing cocktails.",
                content:'<p class="content-modal-text">'+
                "A traditional steakhouse perched on the tropical mountainside with ocean views. Come with an appetite for everything from mussels that transport you to the Sorrento Coast of Italy, to the melt-in-your-mouth dry-aged steaks, exciting array of vegetarian goodness, and decadent desserts."
                +'</p>'+
                '<p class="content-modal-text">'+
                "The concept behind BocaDos Steak House is one dedicated to providing exquisite choices, high quality and dreamy satisfaction. Dishes can be shared for a more casual experience, combined with fresh salads and vegetable dishes or you may opt for a full course meal, pairing your dining with fine wine, craft beer or refreshing cocktails."
                +'</p>'
            },
            hiroshi: {
                title: 'Hiroshi',
                subtitle: 'Japanese Cuisine',
                comment: '',
                paragraph1: "The recipes accentuate the ultra-freshness of the ingredients selected for these culinary creations that will tingle and delight the taste buds. Its minimalist concept is playful yet sensual, colorful yet elegant, flavorsome yet subtle, tempting you to experiment way beyond your appetite.",
                paragraph2: "Prepared with delicacy, precision and originality, the Hiroshi menu represents Japanese cuisine eclecticism offering sashimi, sushi, rolls, soups, tempura, yakitori, lobster, shrimp, chicken, steak and vegetables in a medley of succulent choice.",
                content:'<p class="content-modal-text">'+
                "The recipes accentuate the ultra-freshness of the ingredients selected for these culinary creations that will tingle and delight the taste buds. Its minimalist concept is playful yet sensual, colorful yet elegant, flavorsome yet subtle, tempting you to experiment way beyond your appetite."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Prepared with delicacy, precision and originality, the Hiroshi menu represents Japanese cuisine eclecticism offering sashimi, sushi, rolls, soups, tempura, yakitori, lobster, shrimp, chicken, steak and vegetables in a medley of succulent choice."
                +'</p>'
            },
            noi: {
                title: 'NOI',
                subtitle: 'Italian Restaurant',
                comment: '',
                paragraph1: "NOI is an Italian restaurant located adjacent to the Lobby where flavorful cuisine and modern art hold hands because… food is art. While traditional Italian restaurants conjure images of brick walls and dimly lit spaces illuminated by red and white checkered tablecloths, NOI emerges on the scene with a completely new concept.",
                paragraph2: "With its double height ceiling and modern design, NOI is the symbol of contemporary art. The space is predominately white with gold accents punctuated by a colorful wraparound wall mural more than 3 meters high that tells the history of humanity with the word PEACE as the focal point above the fabulous show kitchen.",
                content:'<p class="content-modal-text">'+
                "NOI is an Italian restaurant located adjacent to the Lobby where flavorful cuisine and modern art hold hands because… food is art. While traditional Italian restaurants conjure images of brick walls and dimly lit spaces illuminated by red and white checkered tablecloths, NOI emerges on the scene with a completely new concept."
                +'</p>'+
                '<p class="content-modal-text">'+
                "With its double height ceiling and modern design, NOI is the symbol of contemporary art. The space is predominately white with gold accents punctuated by a colorful wraparound wall mural more than 3 meters high that tells the history of humanity with the word PEACE as the focal point above the fabulous show kitchen."
                +'</p>'
            },
            dao: {
                title: 'DAO',
                subtitle: 'Contemporary Chinese Cuisine',
                comment: '',
                paragraph1: "DAO is a new gourmet contemporary chinese cuisine concept that is the centerpiece of Mousai located in between the two towers. This elevated restaurant and lounge offers gorgeous views of the surrounding tropical rainforest with Mondrian style glass walls. Its double height interior adds a depth of dimension to the remarkable space. Featuring contemporary Chinese design with curved and continuous lines and a striking red, black and white décor accentuated by a blend of fanciful marble, tapestry, ceramics, and red woods. DAO creates a unique experience for diners in an exceptionable setting.",
                content:'<p class="content-modal-text">'+
                "DAO is a new gourmet contemporary chinese cuisine concept that is the centerpiece of Mousai located in between the two towers. This elevated restaurant and lounge offers gorgeous views of the surrounding tropical rainforest with Mondrian style glass walls. Its double height interior adds a depth of dimension to the remarkable space. Featuring contemporary Chinese design with curved and continuous lines and a striking red, black and white décor accentuated by a blend of fanciful marble, tapestry, ceramics, and red woods. DAO creates a unique experience for diners in an exceptionable setting."
                +'</p>'
            },
            karuma: {
                title: 'Karuma',
                subtitle: 'Gourmet Grill by TierraLuna Gardens',
                comment: '',
                paragraph1: "Karuma is a brand new steakhouse and grill at TierraLuna Gardens. The word Karuma is a combination of two words from the Mayan language meaning “fire in the night.”",
                paragraph2: "This concept is present in the innovative menu as well as the playful creative design and atmosphere of the restaurant. Visit Karuma and be inspired by our sophisticated take on classic steakhouse and grill favorites with an international flare. Hand crafted specialty cocktails made by our highly trained mixologists, and a spectacular ocean view round out the experience.",
                content:'<p class="content-modal-text">'+
                "Karuma is a brand new steakhouse and grill at TierraLuna Gardens. The word Karuma is a combination of two words from the Mayan language meaning “fire in the night.”"
                +'</p>'+
                '<p class="content-modal-text">'+
                "This concept is present in the innovative menu as well as the playful creative design and atmosphere of the restaurant. Visit Karuma and be inspired by our sophisticated take on classic steakhouse and grill favorites with an international flare. Hand crafted specialty cocktails made by our highly trained mixologists, and a spectacular ocean view round out the experience."
                +'</p>'
            },
            spaImage:{
                title: 'Imagine Spa at Mousai Resort, Puerto Vallarta',
                subtitle:'A unique experience.',
                content: 'The only resort spa in Mexico located on the 12th floor of a 5 Diamond with an ocean front view. We have a large co-ed hydrotherapy and relaxation area with an ocean front view. We offer massages, body treatments, facials and salon services. Also available is the renown Hydra Facial as well as body slimming and facial skin resurfacing treatments with the Binary technology System from Ross (Spain).',
                schedule: 'Everyday: 8:00 am - 8:00 pm',
                spaMenu: 'Download Spa Menu',
                spaGallery:'Spa Photo Gallery'
            },
            tierraLuna:{
                // paragraph1:"The magic of TierraLuna Village is born in the heart of Garza Blanca Preserve, amidst the lush jungle and vast ocean. This fantastic outdoor space, ideally situated on the mountainside, is a place that belongs to its own roots, giving life to a communal play space where gatherings happen and people connect with each other.",
                // paragraph2:"Come to discover TierraLuna’s nine specialty boutiques featuring sustainable fashion garments, local designer jewelry, homemade body care, artisan crafts, ceramics, gifts, and deli and coffee shop. TierraLuna also features a weekly farmers market and art walk as well as a whimsical family carousel.",
                paragraph1:"The magic of TierraLuna Gardens is born in the heart of the Garza Blanca Preserve, amid the lush tropical jungle and the vast blue ocean. This is where the earth and the moon meet as eternal companions.",
                paragraph2:"A place of recreation for the exhibition and conservation of native and exotic plant species traversed by wide trails located in almost 4 hectares of humid forest. The natural beauty of the native specimens will captivate all your senses in a community space. Get to know the diversity of our flora and let yourself be captivated by the uniqueness of each species.",
                paragraph3:""
            },
            hotelActivities:{
                title:'Hotel Activities',
                subtitle:'An activity for everyone',
                content:'Our team has crafted a fun-packed variety of activities to ensure that your stay is both relaxing and fun. Please refer to Front Desk, Concierge, or Pool Concierge team to schedule your favorite activity!',
                activities:{
                    ac1:{
                        title:'',
                        subtitle:'Resort Activity Program'
                    },
                    ac2:{
                        title:'',
                        subtitle:"Kid's Club Program"
                    },
                    ac3:{
                        title:'',
                        subtitle:'Cinema Under the Stars'
                    },
                    ac4:{
                        title:'',
                        subtitle:'Yearly event program'
                    }
                }
            },
            wellness:{
                title:'Wellness',
                subtitle:'Inspire yourself with a calm mind',
                content:'A luxury wellness experience dedicated to igniting your inner muse and deepening your connection with yourself, nature and others while enhancing your authentic beauty both inside and out.'
            }
        },
        exclusive:{
            exclusive:'Exclusive',
            experiences:'Experiences',
            cocktail:{
                title:'COCKTAIL NIGHT & PAIRING DINNER',
                subtitle:'El Cielo + Tafer Resorts March 5th & 6th 2024',
                paragraph1:"Join us for an exquisite evening by El Cielo + Tafer Resorts. With special guests including Lic. Gustavo Ortega, Chef Rogelio Domínguez, Chef Luis Vázquez, and Sommelier Georgina Estrada, indulge in an unforgettable culinary experience. Enjoy a guest reception, wine exhibition, and a delectable dinner crafted by Chef Luis Vázquez. Don't miss this opportunity to elevate your palate and immerse yourself in the world of fine dining. Book your spot now with your concierge."
            },
            iconic:{
                title:'5 iconic spots for instagram captions',
                subtitle:'Vacation Your Way, With All-Inclusive And Room Only Options',
                paragraph1:'Enjoy a photo session tour through 5 different points of the property , capture an unforgettable moment in a unique spots and enjoy the experience.',            
                paragraph2:'At the end , you will be able to purchase a photo frame and print the 5 best photos of your tour.',
                first:'First Spot',
                rooftop:'The rooftop',
                second:'Second Spot',
                orange:'Orange tree',
                third:'Third Spot',
                jungle:'Jungle and waterfall',
                fourth:'Fourth Spot',
                carousel:'Carousel',
                fifth:'Fifth Spot',
                chapel:'The Magnificent Forum'
            },
            byReservation:'By reservations Only',
            local:{
                title:'10.2 The Local Channel Tours',
                subtitle:'Welcome to a world of adventure',
                content:'Embark on the adventure of a lifetime with our unique selection of world-class adventure tours and create unforgettable experiences! Discover the most thrilling things to do in Puerto Vallarta with our unmatched, one-of-a-kind excursions!'
            },
            tailor:{
                title:'Tailor Made Experiences',
                subtitle:'Exclusive Experiences With Added Value',
                content:'Custom-made from start to finish, the luxury services VIP Access creates always start with the most important ingredient: you. Our travel professionals know everything there is to know about the luxury vacations. We choose every amazing activities, the most exclusive services as a privated yachts or privated plane, encounter and luxury accommodation with your interests in mind, making each day an adventure you won’t want to end.'
            },
            
        },
        residence:{
            residence:'Residence',
            club:'Club',
            welcome:{
                title:'Welcome to Residence club',
                subtitle:'High Quality Vacation Ownership',
                paragraph1:'We value your time, your pocket and your loyalty which is why we created Residence Club to honor our MVGs (most valuable guests)',
                paragraph2:'You already experienced the TAFER special hold of allure and we want to reward your time spent with us which has made us grow and improve as a company but above all keep expanding to offer more dazzling experiences.',
                question:'Do you want to know how you can convert your vacation record with us into equity for your next step as a co owner?',
            },
            step:'step',
            steps:{
                content1:'<label>We understand you have been traveling all day and need time to decompress from traveling.</label>'+
                         '<label>Feel free to check into your room, unwind and get a start on your Vacation, please let us know if you need any additional refreshments. Once you are relaxed and ready, we invite you to head down and have a chat with your personal VIP concierge. Who will guide you through our exclusive hotel service, and make your stay even more memorable.</label>',
                content2:'<label>Your personal concierge will be showing you your vacational record with us and explaining how all of your points can turn into equity.</label>',
                content3:'<label>Once you preview the club membership, the benefits will be activated. Keep enjoying your wonderful stay with us!</label>'
            },
            concierge:{
                title:'Your Personal concierge',
                subtitle:'Share The Gift Of Treasured Moments',
                content:"A personal concierge at your disposal to assist you in your vacation experience at all times is the promise to be fulfilled. We take care of planing your Vacation details so you don't have to spend your time doing anything but having fun!"
            },
            destinations:{
                title:'Destinations',
                subtitle:'Tafer Residence Club',
                content:'Imagine taking frequent vacations to paradise, choosing from a number of top destinations in Mexico that you can call home. Choice and quality remain at the heart of your exclusive vacation club membership; all you have to do is decide where and when you wish to visit.'
            },
            resorts:{
                title:'the RESORTs',
                subtitle:'A Resort For Every Occasion And Persuasion',
                content:'Explore each of the fabulous luxury resorts that make your Tafer Residence Club membership stand out from the rest. Using your annual club point allowance, you can reserve accommodation at any one of the following resorts. No stay will be quite the same as the next, though you can be sure of first-class quality and extraordinary experiences at any of the resorts.'
            },
            memberships:{
                title:'Types of ELITE Membership',
                subtitle:'Tafer Residence Club',
            },
            referral:{
                title:'Tafer referral program',
                subtitle:'A Program Created by Members to Members',
                paragraph1:'Wouldn’t you love to have your closest friends as your neighbors in your vacation home here at Garza Blanca Residence Club? Then why not spread the word about how great your membership is, and at the same time be eligible to get something in return for yourself!',
            },
            sabio:{
                title:'Sabio Concierge',
                subtitle:'The Lifestyle & Travel Concierge.',
                paragraph1:'An invitation-only private club that serves an elite-circle of people by delivering excellence in their lifestyle, travel, and business ventures.',
                paragraph2:'Sabio brings diverse backgrounds, decades of experience, and worldwide connections to create rich and unique experiences for you and your family, and in the best tradition of our signature touch, all our projects are carried out with strict confidentiality as we respect the privacy of our clients.',
                paragraph3:"You're now ready to enjoy the benefits of Membership."
            },
            one:{
                title:'MEMBERSHIP 101 CLASS',
                subtitle:'Membership Overview Class',
                paragraph1:'Explore all the benefits of your membership, with personalized information from top membership executives. Bring all your questions or past experiences to share with other members an enjoy being part or the tafer comunnity.',
                schedule:'SCHEDULE',
                scheduleDay:'Every Friday',
                scheduleHour:'9:00 am',
                location:'LOCATION',
                locationText:"At the Members' Sales Room located in Tower 3, floor 7"
            }
        },
        press:{
            dedicated:'DEDICATED',
            press:'PRESS',
            news:{
                title:'Important news for important people',
                subtitle:'Daily top news',
                today:'Today',
                news:'news'
            },
            weekly:{
                title:'Weekly Back Issues',
                subtitle:'Daily top news'
            }

        },
        btns: {
            viewMore: 'View More',
            learnMore: 'Learn More',
            explore: 'Explore The Property',
            download: 'Download',
            contactConcierge: 'Contact Your Concierge',
            meetTierraLuna: 'Meet TierraLuna',
            map: 'Map',
            program: 'Program',
            calendar: 'Music Calendar',
            tourCatalog:'The Tour Catalog',
            chatTravelExpert:'Chat with our travel expert',
            scheduleAppointment:'Schedule Appointment',
            checkbenefits:'Check all benefits',
            booknow:'Make a Reservation',
            chatConcierge:'Chat with you concierge',
            call:'Call',
            whatsapp:'WhatsApp',
            bookYourClass:'Book your class',
            cancel:'Cancel',
            select:'Pick',
            submit:'Submit',
            updateNow:'Update Now',
            anotherTime:'Another Time',
            activities:'Activities'
        },
        scheduleForm:{
            subtitle:'Please schedule an appointment with your personal concierge, is a pleasure to assist you.',
            date:'Date',
            hour:'Hour',
            message:'Message',
        },
        banner:{
            welcome:'Welcome Virtual Bonus',
            content:
            '<p>Congratulations you have received a virtual gift as a welcome to '+
            '<label style="color:var(--vip-gold); font-weight: 500;">VIP Access</label>,'+
            'use your bonus on Local Channel tours or at the membership preview.</p>',
            disclaimer:'Applicable only on Local Channel tours or at the membership preview. Ask your concierge how to redeem your welcome bonus. Promotions are valid during your stay at our home resort Garza Blanca & Mousai Puerto Vallarta. Promotion is only valid per stay and per room.'
        },
        alerts:{
            scheduleForm:{
                success:{
                    title:'Your Appoinment has been saved',
                    content1:''
                },
                error:{
                    title:'Something has been Wrong',
                    content1:''
                }
            }
        },
        placeholders:{
            dates:'Select a Date',
            time:'Select a Time',
            messageQ:'How can we help?'
        },
        termsAndConditions:{
            term:'Terms',
            conditions:'and Conditions',
            terms:{
                introduction:{
                    paragraph1:'Please take time to read these terms and conditions (defined below) governing the www.vipaccess.taferresorts.com website. By accessing this website, you agree to accept, without limitation or exceptions, all of these terms and conditions of use.',
                    paragraph2:'When entering the website, you agree to receive personalized details of special offers and promotions. An unsubscribe link will be featured on each message we sent to you, should you no longer wish to receive these emails. VIP Access by Tafer Resorts will not share your details with any third party. These terms and conditions (defined below) shall be governed by and construed in accordance with the laws of Mexico.'
                },
                restrictions:{
                    title:'Resort restrictions',
                    paragraph1:'You may not bring the following into the Resort: pets or livestock; pillows, bed sheets, duvets or other items of bedding; any electrical appliances and/or equipment for heating and/or cooking purposes. The Resort reserves the right to remove and/or confiscate any of the above items found in any room immediately without notice to you and to charge you for any costs incurred for taking such action.',
                    paragraph2:'No guests aged under 18 years old will be permitted at Hotel Mousai.',
                    paragraph3:'Air cameras and drones: for the privacy of all our guests and staff, the use of air cameras and drones is prohibited for use within any of the areas at the hotel, whether that be at the beach, from your suite or any other location throughout the grounds. Thank you for your cooperation and understanding.'
                },
                behavior:{
                    title:'Guests behavior',
                    paragraph1:'You are expected to conduct yourself at all times in an orderly and acceptable manner and not to disrupt the quiet enjoyment of other guests. We reserve the right to immediately terminate your booking and the booking of any members of your party if we (acting reasonably) deem your conduct to be in breach of this clause. Conduct that we reasonably consider inappropriate includes but is not limited to:',
                    opt1:'Creating an inappropriate level of noise',
                    opt2:'Drunken or unruly behavior',
                    opt3:'Any behavior which other guests or staff find offensive in any way.',
                    paragraph2:'We will at all times act reasonably when making any determination under this clause. In the event that we terminate your booking, you will be required to leave your accommodation immediately. You will be held liable for any damage or loss caused by you or a member of your party. Full payment for any such damage or loss must be paid prior to your departure. If you fail to make payment, you will be responsible for meeting any claims (including legal costs) subsequently made against us as a result of your actions together with all costs we incur in pursuing any claim against you.',
                    paragraph3:'Our obligations to you come to an end when the booking is terminated. We will have no obligation to refund you for lost accommodation and we will not pay for any expenses or costs incurred as a result of the termination.'
                }
            },
            title:'PRIVACY POLICY',
            policy:{
                information:{
                    title:'Types of information we collect:',
                    paragraph1:'VIP Access By Tafer Resorts collects information from our guests and visitors and tour websites in order to provide an utility of our site that meets your needs. Information may be collected to:',
                    paragraph2:'(i) Complete reservations or information requests, (ii) purchase products or services, (iii) register for membership programs, (iv) submit job applications,(v) responding to communications that we send you (ex. surveys, promotional offers or reservation confirmations), (vi) meet your personal preferences, (vii) complete applications or services that we offer advice, (viii) work with third parties, including information gathering public use of social event websites, help us better to understand your interests and provide a more personalized services, (ix) use our applications on your electronics, or(x) to facilitate the transmission of messages to an email from a friend, if desired.',
                    paragraph3:'The personal information we collect may include your name, address and personal and professional e-mail addresses, phone numbers and fax numbers, details of the credit card, date of birth, gender and information related to your lifestyle, such as room preferences, leisure activities, names and ages of children and any other necessary information that is useful to fulfill special requests (e.g. certain health conditions require special room accommodations).',
                    paragraph4:'VIP Access By Tafer Resorts may also collect information that is not considered personally identifiable like your use of our websites, communication preferences, travel habits, integrated data related to their lodgings and responses to promotional offers and surveys.',
                    paragraph5:'Note that for a better protection of your information, you should not include information related to your credit card in their electronic communications unless you specifically ask for it in order to complete the process of a reservation or transaction on our websites or brand in our customer service centers.'
                },
                collection:{
                    title:'Collection and use',
                    paragraph1:'VIP Access By Tafer Resorts is fully committed to provide information regarding the collection and use of persons who provide us with the people who visit and use our websites, products and services. We do not ask for information unless we need it for an oriented use. Some of the main reasons we collect information include:',
                    paragraph2:'Provide services such as processing a transaction (e.g. reservations and responding to a request for information). Send you marketing communications relating to products and services offered by VIP Access By Tafer Resorts our strategic partners and other trusted third parties.',
                    paragraph3:'Conduct market research via surveys to better meet your needs, improve the quality of our websites and your stay in our hotels, and other communication activities, advertising or promotional.',
                    paragraph4:'You can choose not to receive commercial emails or surveys of VIP Access By Tafer Resorts at any time by clicking on the link included in the communication or by contacting us directly, as well as not to receive emails or direct marketing surveys or telemarketing. Click here for the Update of your communication preferences in the contact us section of this privacy statement. You always have the choice not to submit your information or to decide what communications you want from us. However, doing so, certain transactions might be affected. For example, not providing a name will prevent the processing of reservations.'
                },
                disclose:{
                    title:'Processing and disclosure',
                    paragraph1:'In most cases, the information you provide is added to our local or international database. As standard practice, VIP Access By Tafer Resorts does not sell, rent or give physical possession of your database to third parties outside the system VIP Access By Tafer Resorts may disclose your personal data to other people in the situations listed below:',
                    opt1:'We have your consent.',
                    opt2:'In situations where it is necessary to give your personal information to provide products or services you want (for example; a vacation package).',
                    opt3:'When companies or services providers perform business activities on behalf of VIP Access By Tafer Resorts (e.g. processing credit cards, customer service, market research administration or management services database require this information). In the event that VIP Access By Tafer Resorts merges with or is acquired by another company.',
                    opt4:'To comply with the requirements and legal obligations or regulations established by the applicable legislation or as a result of a court order or judicial citation.',
                    opt5:'In emergencies such as life saving, preserving the health or property of a person.',
                    paragraph2:'In the event that VIP Access By Tafer Resorts share information as mentioned above, try to limit the scope of the data supplied as necessary to carry out the specific function. Unless precluded by legal process, we require third parties to protect your information and comply with laws and regulations for the protection of personal data application.'
                },
                consent:{
                    title:'Consent',
                    paragraph1:'As part of our commitment to keep you informed, we may send letters or emails, communicate by telephone or other means to notify you of new products and services of VIP Access By Tafer Resorts along with upcoming special offers from TAFER RESORTS MANAGEMENT S.A. DE C.V... events, enhancements or other relevant information that may be of interest. You have the option not to accept delivery of all these communications of TAFER RESORTS MANAGEMENT S.A. DE C.V.. either following the directions included in our emails or other communications or by communicating directly with TAFER RESORTS MANAGEMENT S.A. DE C.V.',
                    paragraph2:'Our intentions are to keep all our guests and visitors informed and also allow them to reap the benefits offered by VIP Access By Tafer Resorts and its strategic partners. However, if you reside outside the United States, certain regulations on data protection may require us to obtain your permission to send you information you have not requested.',
                    paragraph3:'In some cases, your consent maybe implied by the nature of the service requested or transaction undertaken. As an example, VIP Access By Tafer Resorts may collect and use information that you voluntarily give us, as well as behavior data or information collected globally. Also, we will be deemed to have consented to receive those necessary communications to complete the transactions and provide the services requested.',
                    paragraph4:'In certain cases, we will request your explicit consent to collect information considered confidential subject to certain laws of privacy of information (e.g. information revealing racial or ethnic origin, political opinions or health conditions).',
                    paragraph5:'You may object to VIP Access By Tafer Resorts disclose your information to third parties except when:',
                    paragraph6:'(i) retained service providers to give services on behalf of VIP Access By Tafer Resorts (ii) the law seeks disclosure, (iii) disclosure data is necessary for reasons of public interest or national law, (iv) the disclosure is necessary to protect vital interests of the host, (v) the information is collected from publicly available sources, (vi) the disclosure is for retrospective processing of information for statistical or historical purposes or to improve the experiences of our customers or(vii) where a supervisory authority (as a defender of the rights of privacy, a data protection authority or similar authority) allows such disclosure.',
                },
                access:{
                    title:'Access to personally identifiable information',
                    paragraph1:'To ensure that your information is accurate and updated, we encourage you to regularly review and update such information as appropriate (for example, in the event of a change of address or email address or if you want to add other communication systems so that we may contact you). In an effort to prevent unauthorized disclosure of your information, you may be asked to provide a proof of your identity (or other test) along with the application form duly completed.',
                    paragraph2:'Occasionally, it is possible that the information you have requested to be removed is retained in certain files for a period of time in order to resolve any dispute properly or solve a problem. Also, some information may be kept indefinitely in the copies of "backup" or in the log files due to technical constraints, or financial or legal requirements.',
                    paragraph3:'Therefore, you cannot always expect to completely remove all your information from our database according to your request referred to the appropriate contact information listed below to contact us if you want to update your communication preferences, be removed from our marketing list or send a question or general request.'
                },
                measure:{
                    title:'Safety measures',
                    paragraph1:'VIP Access By Tafer Resorts recognizes the importance of information security and is constantly reviewing and improving standards and technical, physical and logical security procedures. All websites and servers owned byVIP Access By Tafer Resorts implemented security measures to help protect your data against accidents, loss, misuse, unauthorized or illegal access, disclosure or alteration while under our control. Although, there is no "guaranteed security" on or off the Internet, we protect your information using technical, appropriate, administrative and procedural safeguards among which are password controls, "firewalls" (and use of up to 256-bit encryption based on a Class 3Digital Certificate issued by VeriSign, Inc.',
                    paragraph2:'This allows us to use Secure Sockets Layer (SSL), an encryption method used to help protect your data from interception and hacking attempts while in transit).'
                },
                tecnologies:{
                    title:'Online technologies',
                    paragraph1:'VIP Access By Tafer Resorts and its business partners may use cookies, web beacons (web beacons) to obtain information about you while visiting our websites. A "cookie" is a small text file sent to your browser from a web server and stored on your'
                               +"computer's hard disk. Cookies that VIP Access By Tafer Resorts assigned a unique identifier to the computer, in turn, becomes your identification card whenever you visit a website of TAFER RESORTS MANAGEMENT S.A. DE C.V.",
                    paragraph2:'Cookies were designed to save you time because they help us to provide a personalized service without having to remind us of your preference search time when you visit our website. Cookies from VIP Access By Tafer Resorts are not designed to damage your files or to read the information that you stored on the hard drive of your computer.',
                    paragraph3:'Cookies can also help us provide you with a personalized online service in the following ways:',
                    opt1:'Remember your username and password for future visits easier and faster by logging.',
                    opt2:'Ensure that you have the appropriate frames and content.',
                    opt3:'Ensure you receive all the information requested.',
                    opt4:'Help us provide communications that are relevant and related to your interests and your location.',
                    paragraph4:'Cookies from VIP Access By Tafer Resorts are limited only to our Web sites and are not designed to follow while surfing the Internet once you have left the websites belonging to VIP Access By Tafer Resorts We track the usage of our websites in order to meet their needs and how best to help in the future, so you can find information more easily. Note that in addition to the "cookies" by VIP Access By Tafer Resorts it is possible that third parties place cookies on the hard drive of your computer. VIP Access By Tafer Resorts is not responsible for the presence or absence of "cookies" of third or technological features or practices that may use these third parties as to their cookies.',
                    paragraph5:'If you prefer not to enjoy the benefits offered by "cookies", you can configure your Internet browser to try these cookies in different ways. Depending on the type of browser you use, you can set your browser so that: (i) you are prompted to accept or reject cookies individually or(ii) avoid accepting any cookies. You should consult the supplier or manufacturer of your web browser for specific details about security related cookies. However, you should also consider that rejecting cookies may impede the achievement of certain transactions on our websites and our ability to recognize your browser from one visit to the next.',
                    paragraph6:'In addition, VIP Access By Tafer Resorts uses web markers used to count the number of people visiting certain websites. The information we collect is used with these markers in general and does not contain any personal data.VIP Access By Tafer Resorts can use this information to improve marketing programs and content.',
                    paragraph7:'We also work with suppliers and marketing partners that help us send custom advertising and produce content that we think will interest you. These vendors and other partners include advertisers, advertising agencies, advertising networks, specialists in market segmentation and exchange of data, analytics providers and other similar providers. These partners and providers may use cookies and Web bookmarks to collect information about your online activity (e.g. sites and pages you have visited in VIP Access By Tafer Resorts sites and other sites) to help VIP Access By Tafer Resorts (and, in some cases, external advertisers) to send a personalized content or advertising while in VIP Access By Tafer Resorts site or other sites.',
                    paragraph8:'For example, if you visit a site of VIP Access By Tafer Resorts and see one of our hotels or booking of a hotel room, you may see an advertisement later when visiting our other website. You may also ask you for any of these providers to send our advertising to consumers whose online behavior patterns in sites not VIP Access By Tafer Resorts indicate who might be interested in traveling. In other cases, we make special arrangements with certain advertisers that we think may be of interest to visitors to our website. These advertisers are allowed to send messages to the two brands (e. g. VIP Access By Tafer Resorts and brand advertiser) directly to consumers who have visited our web pages.'
                },
                websites:{
                    title:'Links to other websites',
                    paragraph1:'In order to anticipate their needs, VIP Access By Tafer Resorts offers links to other websites and information for your benefit. VIP Access By Tafer Resorts is not responsible for the content that facilitates or included in any independent website, including, without limitation, any claim advertising or business practices. While VIP Access By Tafer Resorts will protect the information found on the websites owned and operated, we cannot control nor assume any responsibility for the privacy practices of third party websites, including websites owned or controlled by independent franchisees and other owners of hotels, resorts, timeshare or residential properties that may use the name of a brand of VIP Access By Tafer Resorts or websites not controlled and authorized by VIP Access By Tafer Resorts The third-party websites that are accessed through links on our websites have privacy practices and data collection, as well as various security measures. We assume no responsibility for the practices, policies and security measures implemented by third parties on their websites.',
                    paragraph2:'We encourage you to contact and ask them about their privacy practices, policies and security measures before disclosing any information. We recommend you to review the privacy policies and statements relevant to websites related to understand how those sites collect, manipulate and store information.'
                },
                minors:{
                    title:'Minors',
                    paragraph1:'VIP Access By Tafer Resorts does not seek or wish to receive information directly from minors. However, we cannot always determine the age of persons who access and use our websites. In the event that a minor (defined according to the criteria established by applicable law) provides us with your data without the consent of their parents or guardians, we ask the parent or guardian to contact us and ask us to delete this information and unsubscribe by the minor regarding future commercial communications from TAFER RESORTS MANAGEMENT S.A. DE C.V.'
                },
                cookies:{
                    title:'Cookies',
                    paragraph1:'Cookies are used by VIP Access By Tafer Resorts to evaluate visits to the website in order to improve the experience offered. In technical terms, cookies are a packet of data that is sent between the Internet server and browser in order to identify the user and evaluate their access to the website. The small “cookie” file is transferred to your computer when you visit the website. Any transferred cookies only contain the information that VIP Access By Tafer Resorts sends to your computer. Even though the cookies help us to identify your computer, no private data can be read with the cookies. If you wish to deactivate the cookies function, you may do so by changing the settings on your browser. However, by deactivating the cookies, your experience of the website may be diminished as you may not be able to see all the features displayed correctly or access some functions.'
                },
                using:{
                    title:'By using our website you accept our use of cookie',
                    contactUs:'Contact Us',
                    update:'Update your communication preferences.',
                    paragraph1:'For questions or suggestions regarding our privacy practices of information regarding this Privacy Statement, or if you want to update, modify or cancel marketing communications from VIP Access By Tafer Resorts you can contact us by any of the ways listed below:',
                    byPhone:'By phone:',
                    phone:'+52 322 327 3663'
                }
            }
        },
        update:{
            title:'Update available',
            content:"Let's update your VIP Access to Enjoy latest Features.",
        },
        conciergeWidget:{
            customer:'Concierge hours from 7:30 am to 9:00 pm'
        },
        banners:{
            banner50:{
                welcome:'Welcome Virtual Bonus',
            content:
            '<p>Congratulations you have received a virtual gift as a welcome to '+
            '<label style="color:var(--vip-gold); font-weight: 500;">VIP Access</label>,'+
            'use your bonus on Local Channel tours or at the membership preview.</p>',
            disclaimer:'Applicable only on Local Channel tours or at the membership preview. Ask your concierge how to redeem your welcome bonus. Promotions are valid during your stay at our home resort Garza Blanca & Mousai Puerto Vallarta. Promotion is only valid per stay and per room.'
            },
            karumabanner:{
                content:
                '<span>Seize this opportunity to savor the finest in culinary mastery and elevated luxury at '+
                '<label style="color:var(--vip-gold); font-weight: 500;">Karuma Restaurant.</label></p>'+
                '<p>Secure your reservation today and receive the remarkable 10% discount on your bill. We eagerly anticipate your arrival.</p>',
                restaurant:'Karuma The Art of Grill',
                quote:'Best Steakhouse in Puerto Vallarta'
            },
            session:{
                content:'<p>Capture the best of your vacation with your loved ones and live the experience of a '+
                '<label style="color:var(--vip-gold); font-weight: 500;"> professional photo session</label></p>'+
                ' at this iconic resort.</p>'
            },
            spa:{
                content:"<p class='lh-base'>Spa Imagine sits in pride as the only 5 diamond luxury spa in Jalisco. Don't miss the opportunity to book your appointment.</p>"
            }
        }

    },
    'es-MX':{
        header:{
            nav:{
                home:'Inicio',
                property:'La Propiedad',
                exclusive:'Experiencias Exclusivas',
                rewards:'Recompensas TAFER',
                residence:'Residence Club',
                press:'Dedicated Press'
            },
        },
        footer:{
            nav:{
                home:'Inicio',
                property:'La Propiedad',
                exclusive:'Experiencias Exclusivas',
                rewards:'Recompensas TAFER',
                residence:'Residence Club',
                press:'Dedicated Press'
            },
            navigation:'Navegacion',
            terms:'Términos y condiciones',
            privacy:'Políticas de privacidad',
            rights:'Todos los derechos reservados'
        },
        home:{
            welcome:'Bienvenidos a',
            the:'VIP',
            vipAccess:'Access',
            messageMain:'Descubre experiencias extraordinarias',
            exclusive:{
                title:'Experiencias Exclusivas',
                subtitle:'Explora nuestras auntenticas y unicas experiencias Tafer Resorts.',
                iconic:'5 Lugares Iconicos para instagram.',
                iconic2:'Mejora la sessión con la vista del rooftop y Champaña.',
                local:'10.2 The Local Channel',
                tailor:'Experiencias a su medida ',
                cocktail:'Cocktail Night & Pairing Dinner'
            },
            property:{
                title:'La Propiedad',
                subtitle:'Descubre las vacaciones más extraordinarias.',
                content:"La Propiedad ofrece uno de los más lujosos y elegantes complejos de playa. Promete experiencias únicas y fascinantes para todas las edades. ubicado en Puerto Vallarta con 85 hectáreas de exuberante selva tropical, albergar el primer y único resort calificado “AAA Five Diamond” en Puerto Vallarta, el exclusivo Hotel Mousai and Garza Blanca Preserve Resort & Spa son resorts de playa de lujo que ofrecen una variedad sofisticada de amenidades y servicio a la habitación para todos y cada uno de nuestros huéspedes.",
                heat:'Piscinas al aire libre con jacuzzis y concierge de piscina.',
                white:'Playa de arena blanca',
                hiking:'Rutas de senderismo en exuberantes paisajes tropicales',
                gourmet:'Spa de clase mundial',
                world:'World-class Spa',
                kids:'Kids Club',
                oceanView:'Gimnasio con vista al mar y cancha de tenis al aire libre',
                more:'Y más'
            },
            residence:{
                title:'Residence Club',
                subtitle:'Puerto Vallarta',
                content:"TAFER Residence Club llevará sus vacaciones a un nuevo nivel de perfección. Armonizando los lujos de un resort 5 estrellas con la exclusividad y autonomía de un propietario vacacional, la magnífica experiencia de Residence Club va de la mano de nuestro más capacitado personal que se asegurará de satisfacer sus necesidades, itinerarios y preferencias individuales a la perfección y con la mejor disposición."
            },
            press:{
                title:'Prensa',
                subtitle:'Noticias Importantes para gente importante.'
            },
            concierges:{
                title:'¡Gracias por contactarnos¡',
                content: 'Nuestro equipo de Concierge esta listo para atenderte. En breve serás redireccionado a nuestro chat de concierge.',
                schedule:'Contactanos de 9 am hasta 5 pm'
            }
        },
        property:{
            the:'La',
            property:'Propiedad',
            garza:{
                title:'Garza Blanca Preserve Resort & Spa',
                subtitle:'Todo Incluido En Puerto Vallarta',
                paragraph1:"Bienvenido a Garza Blanca Preserve Resort & Spa, uno de los más lujosos hoteles todo incluido de México, ubicado en Puerto Vallarta con 85 hectáreas de exuberante selva tropical, este elegante complejo de playa promete experiencias únicas y fascinantes para todas las edades.",
                paragraph2:"Ofrece el plan todo incluido, así como el plan solo habitación con las mejores tarifas de hotel para sus vacaciones en Puerto Vallarta.",
                
            },
            mousai:{
                title:'Hotel Mousai',
                subtitle:'Puerto Vallarta',
                paragraph1:"Hotel Mousai en Puerto Vallarta, México, ofrece a los huéspedes mayores de 18 años (hotel solo adultos) experiencias exclusivas, en la que podrán disfrutar el arte de vivir, en un entorno tropical cerca de la playa.",
                paragraph2:"Vive experiencias 5 Diamantes en Hotel Mousai. Con espectaculares Rooftops con vistas panorámicas de Bahía de Banderas y alimentos gourmet pertenecientes a diversas cocinas del mundo, Mousai promete una estancia opulenta donde el lujo y la relajación serán la pieza angular de tus vacaciones."
            },
            propertyMap:'Mapa de la Propiedad',
            culinaryOffer: {
                title: 'Oferta Culinaria',
                subtitle: 'Gastronomía Gourmet',
                content: 'Sumérjase en una experiencia culinaria extraordinaria. Seduzca sus sentidos con los sabores y aromas de nuestras diferentes opciones gastronómicas, y disfrute de comidas deliciosamente inolvidable. Cada bocado es un viaje épico para el paladar que estimulará todos tus sentidos, dejándote en un estado puro de satisfacción.',
                allInclusive:'Ofertas Culinarias Todo Incluido',
                gourmet:'Gastronomía Gourmet Todo Incluido',
                premium:'Experiencias Culinarias Premium.',
                dressCode:{
                    title:'CÓDIGO DE VESTIR PARA CENAS',
                    content:'Le solicitamos seguir nuestro código de vestimenta para hombres y mujeres para cenas dentro del resort.',
                    avoid:'Por Favor Evite:',
                    contentAvoid:"Todo tipo de playeras &#8739; Trajes de baño/salidas de playa &#8739; Sandalias &#8739; Calzado de piscina y playa &#8739; Ropa y tenis deportivos "
                }
            },
            aquazul: {
                title: 'Aquazul',
                subtitle: 'Snacks y Restaurante Italiano',
                comment: '',
                paragraph1: "Aquazul at Garza Blanca Preserve takes poolside favorites like burgers, salads, guacamole and ceviche and gives them a sophisticated twist. Enjoy Views of the infinity pool, soft sands and crystalline waters.",
                paragraph2: "Aquazul plays with the typical poolside snacks like burgers, salads, guacamole and ceviche giving them a sophisticated twist that reminds you where you are - paradise.",
                paragraph3: "With such an extensive and versatile menu, lunch and snack time become a delightful pleasure for you and your whole family.",
                content:'<p class="content-modal-text">'+
                "Aquazul ha revolucionado los platillos favoritos de nuestros huéspedes, desde las hamburguesas y las ensaladas, hasta los guacamoles y ceviches. Deleita tus papilas mientras disfrutas de una experiencia sensorial de 360°."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Los platillos estéticamente presentados han sido creados por expertos quienes combinaron sabores y texturas deliciosas teniendo en mente la simplicidad. Aquazul ofrece los típicos tentempiés como hamburguesas, ensaladas, guacamole y ceviche, dándoles un toque sofisticado que le recuerda dónde se encuentra – en el paraíso."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Con un menú amplio y versátil experimentará un almuerzo o merienda que se convertirán en un placer agradable para usted y toda su familia."
                +'</p>'
            },
            terrace: {
                title: 'The Terrace',
                subtitle: 'Desayuno americano y mexicano',
                comment: 'SOLO MOUSAI',
                paragraph1: "As the sun rises over the Sierra Madre Mountains in Puerto Vallarta, an eclectic breakfast awaits to inspire you towards a brand new day of unique Hotel Mousai adventures.",
                content:'<p class="content-modal-text">'+
                "Conforme se levanta el sol sobre las montañas de la Sierra Madre en Puerto Vallarta, te espera un ligero desayuno para inspirarte a vivir un nuevo día de aventuras únicas al estilo de Hotel Mousai."
                +'</p>'
            },
            rooftop: {
                title: 'Rooftop',
                subtitle: 'Alta Cocina, sabor de alto nivel.',
                comment: 'SOLO MOUSAI',
                paragraph1: "One of the best rooftop bars in Puerto Vallarta, by day The Rooftop is the perfect place to chill out by the sensational infinity pool and savor gourmet bites. By night, it is the place to go for a magical encounter under the stars. At any time of day or night, The Rooftop is the height of trendy luxury with relaxing spaces, comfy lounge chairs, and legendary libations.",
                content:'<p class="content-modal-text">'+
                "El Rooftop es la atracción principal para un fresco almuerzo para contemplar las estrellas. Tan pronto como salgas del ascensor, te sorprenderá la vista panorámica de la jungla y los colores deslumbrantes del océano, que forman un poderoso telón de fondo para acompañar el viaje de tu paladar."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Te sentirás como si estuvieras flotando en las nubes, con una dimensión de elegancia aventurera mientras disfrutas de tu cena al aire libre. Perfecto para almuerzos o aperitivos gourmet junto a la piscina."
                +'</p>'

            },
            roomService: {
                title: 'Servicio a la habitación',
                subtitle: '24 Horas',
                comment: 'SOLO MOUSAI',
                paragraph1: "iPad technology can be found in each and every suite at Hotel Mousai, which can be used to keep you up-to-date with the services available at the 5 Diamond hotel the perfect tool for you to browse the 24 hour room service menu which includes dishes for breakfast and dinner as well as wine and cocktails.",
                content:'<p class="content-modal-text">'+
                "Puedes encontrar un iPad en todas y cada una de las suites del Hotel Mousai, úsalo para mantenerte al día con los servicios disponibles en el hotel, es la herramienta perfecta para navegar por el menú de Servicio a la Habitación las 24 horas, incluye platos para el desayuno y la cena, así como vinos y cócteles."
                +'</p>'
            },
            blancaBlue: {
                title: 'Blanca Blue',
                subtitle: 'Cocina mexicana renovada',
                comment: '',
                paragraph1: "The best Mexican cuisine in Puerto Vallarta, only at our oceanfront restaurant: Blanca Blue. Discover iconic flavors through innovative gourmet dishes.",
                paragraph2: "The menu provides inspiration for the palate, a marriage of ultra fine dining, iconic Mexican flavors and innovative culinary techniques.",
                paragraph3: "You will enjoy the results of traditional culinary methods used to prepare modern ingredients. Diners will find all of those elements we love most about Mexican food turned into inspiring works of art that ignite the senses and the emotions on a taste odyssey.",
                content:'<p class="content-modal-text">'+
                "Con un exquisito menú lleno de sensacionales platos para deleitar tu paladar, Blanca Blue Restaurant and Lounge ofrece a sus comensales una experiencia culinaria única que te hará vibrar con su perspectiva innovadora de la cocina mexicana."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Con un menú elaborado a partir de ingredientes locales de la más alta calidad, este moderno restaurante gourmet reúne lo último en tendencias y técnicas culinarias, con una increíble ubicación frente al mar. Un enfoque verdaderamente único en Puerto Vallarta para el arte de la buena mesa.."
                +'</p>'
            },
            bocados: {
                title: 'Bocados Steak House',
                subtitle: 'Restaurante de cortes',
                comment: '',
                paragraph1: "A traditional steakhouse perched on the tropical mountainside with ocean views. Come with an appetite for everything from mussels that transport you to the Sorrento Coast of Italy, to the melt-in-your-mouth dry-aged steaks, exciting array of vegetarian goodness, and decadent desserts.",
                paragraph2: "The concept behind BocaDos Steak House is one dedicated to providing exquisite choices, high quality and dreamy satisfaction. Dishes can be shared for a more casual experience, combined with fresh salads and vegetable dishes or you may opt for a full course meal, pairing your dining with fine wine, craft beer or refreshing cocktails.",
                content:'<p class="content-modal-text">'+
                "La mejor manera de disfrutar la cocina gourmet de Bocados Steak House es complementar la comida con tu vino favorito o pedir recomendación al mesero sobre la botella adecuada; del mismo modo, es posible que desee elegir un fresco coctel. Lo que es seguro es que será una experiencia que despertará tus sentidos."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Uno de los mayores atractivos para los huéspedes del Hotel Mousai, es el moderno steakhouse y su magnífica ubicación. Para visitar durante el desayuno, almuerzo o cena y deleitarse con exquisitos bocados mientras disfrutas de la más impresionante vista a Puerto Vallarta y Bahía de Banderas.."
                +'</p>'
            },
            hiroshi: {
                title: 'Hiroshi',
                subtitle: 'Cocina japonesa gourmet',
                comment: '',
                paragraph1: "The recipes accentuate the ultra-freshness of the ingredients selected for these culinary creations that will tingle and delight the taste buds. Its minimalist concept is playful yet sensual, colorful yet elegant, flavorsome yet subtle, tempting you to experiment way beyond your appetite.",
                paragraph2: "Prepared with delicacy, precision and originality, the Hiroshi menu represents Japanese cuisine eclecticism offering sashimi, sushi, rolls, soups, tempura, yakitori, lobster, shrimp, chicken, steak and vegetables in a medley of succulent choice.",
                content:'<p class="content-modal-text">'+
                "Las recetas se acentúan en la frescura de los productos seleccionados para estas creaciones culinarias que serán un deleite para el paladar más sofisticado."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Su concepto minimalista es imaginativo, sensual, colorido, elegante, sabroso y sutil, tentándolo a experimentar más allá de su apetito."
                +'</p>'+
                '<p class="content-modal-text">'+
                "El menú asiático de Hiroshi es preparado con delicadeza, precisión y originalidad, ofreciendo sashimi, sushi, rollos, sopas, tempura, yakitori, langosta, camarones, pollo, carne y verduras en una mezcla colorida y suculenta a su elección. ¡Fresco, natural, contemporáneo – Hiroshi!."
                +'</p>'
            },
            noi: {
                title: 'NOI',
                subtitle: 'Cocina Italiana',
                comment: '',
                paragraph1: "NOI is an Italian restaurant located adjacent to the Lobby where flavorful cuisine and modern art hold hands because… food is art. While traditional Italian restaurants conjure images of brick walls and dimly lit spaces illuminated by red and white checkered tablecloths, NOI emerges on the scene with a completely new concept.",
                paragraph2: "With its double height ceiling and modern design, NOI is the symbol of contemporary art. The space is predominately white with gold accents punctuated by a colorful wraparound wall mural more than 3 meters high that tells the history of humanity with the word PEACE as the focal point above the fabulous show kitchen.",
                content:'<p class="content-modal-text">'+
                "NOI es un restaurante italiano ubicado junto al lobby, donde la deliciosa cocina y el arte moderno se dan la mano porque… la comida es arte. Mientras que los restaurantes tradicionales evocan paredes de ladrillo, espacios tenuemente iluminados y manteles a cuadros rojos y blancos, NOI emerge en escena con un concepto completamente nuevo."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Con su techo de doble altura y un moderno diseño, el restaurante NOI es el símbolo del arte contemporáneo. El espacio es predominantemente blanco con acentos dorados, puntuados por un colorido mural envolvente de más de 3 metros de altura que cuenta la historia de la humanidad con la palabra PAZ como punto focal sobre la fabulosa cocina a la vista."
                +'</p>'
            },
            dao: {
                title: 'DAO',
                subtitle: 'Cocina China Contemporánea',
                comment: '',
                paragraph1: "DAO is a new gourmet contemporary chinese cuisine concept that is the centerpiece of Mousai located in between the two towers. This elevated restaurant and lounge offers gorgeous views of the surrounding tropical rainforest with Mondrian style glass walls. Its double height interior adds a depth of dimension to the remarkable space. Featuring contemporary Chinese design with curved and continuous lines and a striking red, black and white décor accentuated by a blend of fanciful marble, tapestry, ceramics, and red woods. DAO creates a unique experience for diners in an exceptionable setting.",
                content:'<p class="content-modal-text">'+
                "DAO es un nuevo concepto de cocina china contemporánea que es la pieza central de Mousai, y está ubicado entre ambas Torres. Este restaurante y salón elevado ofrece hermosas vistas de la selva tropical circundante, con sus paredes de vidrio estilo Mondrian. Su interior de doble altura añade profundidad al notable espacio. Con un diseño oriental contemporáneo, líneas curvas y continuas y una llamativa decoración en rojo, blanco y negro acentuada por una combinación de mármol, tapiz, cerámica y maderas rojas de fantasía. DAO crea una experiencia única en un entorno excepcional."
                +'</p>'
            },
            karuma: {
                title: 'Karuma',
                subtitle: 'Cocina Contemporánea a la Parrilla por TierraLuna Gardens',
                comment: '',
                paragraph1: "Karuma is a brand new steakhouse and grill at TierraLuna Gardens. The word Karuma is a combination of two words from the Mayan language meaning “fire in the night.”",
                paragraph2: "This concept is present in the innovative menu as well as the playful creative design and atmosphere of the restaurant. Visit Karuma and be inspired by our sophisticated take on classic steakhouse and grill favorites with an international flare. Hand crafted specialty cocktails made by our highly trained mixologists, and a spectacular ocean view round out the experience.",
                content:'<p class="content-modal-text">'+
                "Karuma es un nuevo concepto especializado en alimentos a la parrilla ubicado dentro de TierraLuna Gardens. La palabra Karuma es una combinación de dos palabras del maya, cuyo significado es “fuego en la noche”."
                +'</p>'+
                '<p class="content-modal-text">'+
                "Karuma marca el camino de la innovación, con un menú original que presenta deliciosos cortes de carne así como platillos vegetarianos y veganos, además de una amplia selección de cocteles y bebidas artesanales."
                +'</p>'
            },
            spaImage:{
                title:'Imagine Spa at Mousai Resort, Puerto Vallarta',
                subtitle:'Una experiencia única',
                content: 'El único spa en México ubicado en el decimoquinto piso de un hotel de categoría 5 Diamantes. En el interior se encuentra uno de los más completos circuitos de hidroterapia en Puerto Vallarta que se complementa con una gama de tratamientos de spa de lujo en los que destacan el Tratamiento Facial “HydraFacial” y el Tratamiento Corporal Reafirmante Adelgazante con Tecnología Binary System de Ross (España).',
                schedule: 'Todos los dias: 8:00 am - 8:00 pm',
                spaMenu: 'Descargar Menu Spa',
                spaGallery: 'Galeria de Spa'
            },
            tierraLuna:{
                paragraph1:"Ubicado en el corazón de Garza Blanca Preserve, encontrarás un nuevo concepto de TAFER Hotel & Resorts: TierraLuna Gardens. El lugar ideal para ser uno con la naturaleza.",
                paragraph2:"La magia de TierraLuna Gardens reside en que la luz de la luna, al encontrarse con la belleza del mundo natural, es capaz de cumplir todo sueño terrenal. TierraLuna Gardens está situada en la ladera de la montaña, ofreciendo vistas de la exuberante vegetación que la Sierra Madre y el Océano Pacífico ofrecen a sus visitantes. Es un lugar que pertenece a sus raíces y, si bien al caer la noche la luna ilumina todo a su paso, durante el día, es la tierra del sol eterno.",
                paragraph3:"En este espacio de ensueño, la luna y la tierra se han unido para crear un espacio comunitario que conecta a los visitantes con la naturaleza y a unos con otros.."
            },
            hotelActivities:{
                title:'Actividades del Resort',
                subtitle:'Una actividad para todos',
                content:'Hemos creado una variedad de actividades para asegurar que su estancia sea relajante y divertida. Favor de contactar a nuestros equipos de Recepción, Concierge o Pool Concierge para reservar su actividad favorita.',
                activities:{
                    ac1:{
                        title:'',
                        subtitle:'Programa de actividades del resort'
                    },
                    ac2:{
                        title:'',
                        subtitle:"Programa de actividades para niños"
                    },
                    ac3:{
                        title:'',
                        subtitle:'Cine Bajo las Estrellas'
                    },
                    ac4:{
                        title:'',
                        subtitle:'Programa anual de eventos'
                    }
                }
            },
            wellness:{
                title:'Wellness',
                subtitle:'Inspírate con una mente tranquila',
                content:'Una experiencia de lujo dedicada a encender su musa interior y profundizar su conexión con usted mismo, la naturaleza y los demás mientras realza su auténtica belleza tanto por dentro como por fuera.'
            }
        },
        exclusive:{
            exclusive:'Experiencias',
            experiences:'Exclusivas',
            cocktail:{
                title:'COCKTAIL NIGHT & PAIRING DINNER',
                subtitle:'El Cielo + Tafer Resorts March 5th & 6th 2024',
                paragraph1:"Join us for an exquisite evening by El Cielo + Tafer Resorts. With special guests including Lic. Gustavo Ortega, Chef Rogelio Domínguez, Chef Luis Vázquez, and Sommelier Georgina Estrada, indulge in an unforgettable culinary experience. Enjoy a guest reception, wine exhibition, and a delectable dinner crafted by Chef Luis Vázquez. Don't miss this opportunity to elevate your palate and immerse yourself in the world of fine dining. Book your spot now with your concierge."
            },
            iconic:{
                title:'5 Lugares Iconicos para Instagram',
                subtitle:'Vacacione a su manera, con Todo Incluido y opciones Room Only.',
                paragraph1:'Disfrute una sesión fotográfica en 5 puntos diferentes de la propiedad. Capture inolvidables momentos en lugares únicos disfrutando de la experiencia.',            
                paragraph2:'Al final podrá comprar un marco para sus fotografías e imprimir las cinco mejores fotos de su tour.',
                first:'Primer Lugar',
                rooftop:'The rooftop',
                second:'Segundo Lugar',
                orange:'Orange tree',
                third:'Tercer Lugar',
                jungle:'Jungla y cascada',
                fourth:'Cuarto Lugar',
                carousel:'Carrusel',
                fifth:'Quinto Lugar',
                chapel:'Majestuoso Foro'
            },
            byReservation:'Solo con reservación previa',
            local:{
                title:'10.2 The Local Channel Tours',
                subtitle:'Bienvenido a un mundo de aventuras',
                content:'Embarque en la aventura de su vida con nuestra distintiva selección de tours de clase mundial donde creará inolvidables experiencias. Descubra las actividades más fascinantes que Puerto Vallarta tiene para usted con nuestras exclusivas excursiones.'
            },
            tailor:{
                title:'Experiencias hechas a su medida',
                subtitle:'Experiencias exclusivas con valor agregado ',
                content:'Creadas a su medida de principio a fin con los servicios de lujo que sólo VIP Access puede ofrecer con el factor más importante: usted y su familia. Escogemos increíbles actividades, con el itinerario y las amenidades más exclusivas como yates privados, aviones privados; acomodándose a los intereses que tenga en mente, haciendo cada día una aventura que no querrá que acabe nunca.'
            },
            
        },
        residence:{
            residence:'Residence',
            club:'Club',
            welcome:{
                title:'Bienvenido a Residence Club',
                subtitle:'Tenencia Vacacional de la más alta gama',
                paragraph1:'Valoramos su tiempo, su bolsillo y su lealtad y es por eso que creamos Residence Club en honor a nuestro MVGs (most valuable guests)',
                paragraph2:'Usted ya conoce la experiencia y el encanto TAFER y queremos remunerar el tiempo que ha invertido en nosotros, el cuál nos ha hecho crecer y mejorar como empresa pero sobre todo, nos ha dado la oportunidad de expandir nuestros horizontes para seguir brindando más y mejores experiencias inolvidables.',
                question:'¿Le interesa saber cómo puede convertir su historial vacacional con nosotros en acciones para su próximo paso como copropietario?',
            },
            step:'Paso',
            steps:{
                content1:'<label>Entendemos que ha sido un día largo para usted y su familia y necesita tiempo para acomodarse.</label>'+
                         '<label>Siéntase libre de instalarse con calma y relajarse para comenzar sus vacaciones. Si hay algo en lo que podamos asistirlo o si es que necesita algún refrigerio adicional háganoslo saber y lo atenderemos a la brevedad. Una vez que esté instalado, lo invitamos a bajar y tener una breve charla con su VIP concierge personal quién lo acompañará en su estadía y hará de su estancia aún más memorable.</label>',
                content2:'<label>Su concierge personal le enseñará su historial vacacional con nosotros y le explicará cómo es que puede convertir sus puntos en acciones.</label>',
                content3:'<label>¡Una vez que tenga la vista preliminar a su membresía vacacional sus beneficios serán inmediatamente activados! Continúe disfrutando de su inolvidable estancia con nosotros.</label>'
            },
            concierge:{
                title:'Su concierge personal',
                subtitle:'Comparta los momentos atesorados',
                content:"Una concierge personal estará a su disposición para asistirle en su experiencia vacacional en todo momento; es la promesa que queremos cumplir. Nos encargamos de planear cada detalle de sus vacaciones para que no pierda tiempo en otra cosa más que en disfrutar."
            },
            destinations:{
                title:'Destinos',
                subtitle:'Tafer Residence Club',
                content:'Imagine tomar vacaciones al paraíso con frecuencia, teniendo múltiples opciones dentro de México como destino y poder llamarlo hogar. El privilegio y la calidad permanecen fiel a su exclusiva membresía de club vacacional; lo único que tiene decidir es dónde y cuándo desea vacacionar.'
            },
            resorts:{
                title:'Los Resorts',
                subtitle:'Un Resort para cada ocasión',
                content:'Explore cada fabuloso resort de lujo que hará que destaque su Membresía Tafer Residence Club por sobre las demás. Usando su club point allowance anual podrá reservar su estancia en cualquiera de los siguientes resorts. Ninguna estancia será igual a la otra aunque tenga la certeza que la calidad y las experiencias de primer nivel lo acompañaran a donde vaya.'
            },
            memberships:{
                title:'Categorías de Membresias ELITE',
                subtitle:'Tafer Residence Club',
            },
            referral:{
                title:'Tafer programa de referidos',
                subtitle:'Un programa creado de miembros a miembros',
                paragraph1:'El sueño de todos sería poder tener de vecino a sus amigos más cercanos de vacaciones en Garza Blanca y ese sueño puede ser realidad pasando la voz acerca de lo asombrosa que es su membresía y obtener beneficios con ello.',
            },
            sabio:{
                title:'Sabio Concierge',
                subtitle:'The Lifestyle & Travel Concierge',
                paragraph1:'Una invitación personal a un club privado para la élite brindando excelencia en su estilo de vida, viajes y viajes de negocio.',
                paragraph2:'Sabio le ofrece diversos escenarios, décadas de experiencia y conexiones en todo el mundo para crear experiencias enriquecedoras y únicas para usted y su familia con el toque tradicional que nos caracteriza. Todos nuestros proyectos son llevados a cabo con estricta confidencialidad respetando la privacidad de nuestros clientes.',
                paragraph3:"Está usted listo para disfrutar de los beneficios de la membresía."
            },
            one:{
                title:'Membresías paso a paso',
                subtitle:'Resumen de Membresías',
                paragraph1:'Explore la infinidad de beneficios de su membresía, con información personalizada de parte de nuestros ejecutivos en membresías. Tenga lista todas sus preguntas o experiencias pasadas para compartir con otros miembros y disfrute ser parte de la comunidad TAFER.',
                schedule:'ITINERARIO',
                scheduleDay:'Todos los Viernes ',
                scheduleHour:'9:00 am',
                location:'LOCACIÓN',
                locationText:"Sala de Members’ localizada en Torre 3, piso 7"
            }
        },
        press:{
            dedicated:'DEDICATED',
            press:'PRESS',
            news:{
                title:'Noticias importantes para gente importante',
                subtitle:'Noticias del momento',
                today:'del dia',
                news:'Noticias'
            },
            weekly:{
                title:'Ejemplares atrasados de la semana',
                subtitle:'Noticias del momento '
            }

        },
        btns: {
            viewMore: 'Ver Más',
            learnMore: 'Conocer Más',
            explore: 'Explora la propiedad',
            download: 'Descargar',
            contactConcierge: 'Contacte a su concierge',
            meetTierraLuna: 'Conoce TierraLuna',
            map: 'Mapa',
            program: 'Programa',
            calendar: 'Calendario',
            tourCatalog:'Catálogo del tour',
            chatTravelExpert:'Chatea con nuestro experto de viajes',
            scheduleAppointment:'Cita para el Itinerario',
            checkbenefits:'Conoce los beneficios',
            booknow:'Reserva ahora',
            chatConcierge:'Chat con su concierge',
            call:'Llame',
            whatsapp:'WhatsApp',
            bookYourClass:'Reserve su clase ',
            cancel:'Cancelar',
            select:'Confirmar',
            submit:'Enviar',
            updateNow:'Actualizar Ahora',
            anotherTime:'En otro momento',
            activities:'Actividades'
        },
        scheduleForm:{
            subtitle:'Por favor agenda una cita con tu concierge personal, es un placer asistirte.',
            date:'Fecha',
            hour:'Hora',
            message:'Mensaje',
        },
        banner:{
            welcome:'Bienvenido a Bono Virtual',
            content:
            '<p content-text ms-3 me-3 fs-6>¡Felicidades! Usted ha recibido un regalo virtual como bienvenida a '+
            '<label style="color:var(--vip-gold); font-weight: 500;">VIP Access</label>,'+
            ' aproveche su bono en el Local Channel o en Residence Club.</p>',
            disclaimer:'Solo válido en el Local Channel o en Residence Club. Pregunte a su concierge cómo hacer válido su bono de bienvenida. Las promociones son válidas durante su estancia en nuestro resort Garza Blanca & Mousai Puerto Vallarta. La promoción solo es válida por habitación y por su tiempo de estancia.'
        },
        alerts:{
            scheduleForm:{
                success:{
                    title:'Tu Cita ha sido agendada',
                    content1:''
                },
                error:{
                    title:'Algo ha salido mal',
                    content1:''
                }
            }
        },
        placeholders:{
            dates:'Selecciona una Fecha',
            time:'Selecciona un Hora',
            messageQ:'¿Como puedo ayudarte?'
        },
        termsAndConditions:{
            term:'Terms',
            conditions:'and Conditions',
            terms:{
                introduction:{
                    paragraph1:'Please take time to read these terms and conditions (defined below) governing the www.vipaccess.taferresorts.com website. By accessing this website, you agree to accept, without limitation or exceptions, all of these terms and conditions of use.',
                    paragraph2:'When entering the website, you agree to receive personalized details of special offers and promotions. An unsubscribe link will be featured on each message we sent to you, should you no longer wish to receive these emails. VIP Access by Tafer Resorts will not share your details with any third party. These terms and conditions (defined below) shall be governed by and construed in accordance with the laws of Mexico.'
                },
                restrictions:{
                    title:'Resort restrictions',
                    paragraph1:'You may not bring the following into the Resort: pets or livestock; pillows, bed sheets, duvets or other items of bedding; any electrical appliances and/or equipment for heating and/or cooking purposes. The Resort reserves the right to remove and/or confiscate any of the above items found in any room immediately without notice to you and to charge you for any costs incurred for taking such action.',
                    paragraph2:'No guests aged under 18 years old will be permitted at Hotel Mousai.',
                    paragraph3:'Air cameras and drones: for the privacy of all our guests and staff, the use of air cameras and drones is prohibited for use within any of the areas at the hotel, whether that be at the beach, from your suite or any other location throughout the grounds. Thank you for your cooperation and understanding.'
                },
                behavior:{
                    title:'Guests behavior',
                    paragraph1:'You are expected to conduct yourself at all times in an orderly and acceptable manner and not to disrupt the quiet enjoyment of other guests. We reserve the right to immediately terminate your booking and the booking of any members of your party if we (acting reasonably) deem your conduct to be in breach of this clause. Conduct that we reasonably consider inappropriate includes but is not limited to:',
                    opt1:'Creating an inappropriate level of noise',
                    opt2:'Drunken or unruly behavior',
                    opt3:'Any behavior which other guests or staff find offensive in any way.',
                    paragraph2:'We will at all times act reasonably when making any determination under this clause. In the event that we terminate your booking, you will be required to leave your accommodation immediately. You will be held liable for any damage or loss caused by you or a member of your party. Full payment for any such damage or loss must be paid prior to your departure. If you fail to make payment, you will be responsible for meeting any claims (including legal costs) subsequently made against us as a result of your actions together with all costs we incur in pursuing any claim against you.',
                    paragraph3:'Our obligations to you come to an end when the booking is terminated. We will have no obligation to refund you for lost accommodation and we will not pay for any expenses or costs incurred as a result of the termination.'
                }
            },
            title:'PRIVACY POLICY',
            policy:{
                information:{
                    title:'Types of information we collect:',
                    paragraph1:'VIP Access By Tafer Resorts collects information from our guests and visitors and tour websites in order to provide an utility of our site that meets your needs. Information may be collected to:',
                    paragraph2:'(i) Complete reservations or information requests, (ii) purchase products or services, (iii) register for membership programs, (iv) submit job applications,(v) responding to communications that we send you (ex. surveys, promotional offers or reservation confirmations), (vi) meet your personal preferences, (vii) complete applications or services that we offer advice, (viii) work with third parties, including information gathering public use of social event websites, help us better to understand your interests and provide a more personalized services, (ix) use our applications on your electronics, or(x) to facilitate the transmission of messages to an email from a friend, if desired.',
                    paragraph3:'The personal information we collect may include your name, address and personal and professional e-mail addresses, phone numbers and fax numbers, details of the credit card, date of birth, gender and information related to your lifestyle, such as room preferences, leisure activities, names and ages of children and any other necessary information that is useful to fulfill special requests (e.g. certain health conditions require special room accommodations).',
                    paragraph4:'VIP Access By Tafer Resorts may also collect information that is not considered personally identifiable like your use of our websites, communication preferences, travel habits, integrated data related to their lodgings and responses to promotional offers and surveys.',
                    paragraph5:'Note that for a better protection of your information, you should not include information related to your credit card in their electronic communications unless you specifically ask for it in order to complete the process of a reservation or transaction on our websites or brand in our customer service centers.'
                },
                collection:{
                    title:'Collection and use',
                    paragraph1:'VIP Access By Tafer Resorts is fully committed to provide information regarding the collection and use of persons who provide us with the people who visit and use our websites, products and services. We do not ask for information unless we need it for an oriented use. Some of the main reasons we collect information include:',
                    paragraph2:'Provide services such as processing a transaction (e.g. reservations and responding to a request for information). Send you marketing communications relating to products and services offered by VIP Access By Tafer Resorts our strategic partners and other trusted third parties.',
                    paragraph3:'Conduct market research via surveys to better meet your needs, improve the quality of our websites and your stay in our hotels, and other communication activities, advertising or promotional.',
                    paragraph4:'You can choose not to receive commercial emails or surveys of VIP Access By Tafer Resorts at any time by clicking on the link included in the communication or by contacting us directly, as well as not to receive emails or direct marketing surveys or telemarketing. Click here for the Update of your communication preferences in the contact us section of this privacy statement. You always have the choice not to submit your information or to decide what communications you want from us. However, doing so, certain transactions might be affected. For example, not providing a name will prevent the processing of reservations.'
                },
                disclose:{
                    title:'Processing and disclosure',
                    paragraph1:'In most cases, the information you provide is added to our local or international database. As standard practice, VIP Access By Tafer Resorts does not sell, rent or give physical possession of your database to third parties outside the system VIP Access By Tafer Resorts may disclose your personal data to other people in the situations listed below:',
                    opt1:'We have your consent.',
                    opt2:'In situations where it is necessary to give your personal information to provide products or services you want (for example; a vacation package).',
                    opt3:'When companies or services providers perform business activities on behalf of VIP Access By Tafer Resorts (e.g. processing credit cards, customer service, market research administration or management services database require this information). In the event that VIP Access By Tafer Resorts merges with or is acquired by another company.',
                    opt4:'To comply with the requirements and legal obligations or regulations established by the applicable legislation or as a result of a court order or judicial citation.',
                    opt5:'In emergencies such as life saving, preserving the health or property of a person.',
                    paragraph2:'In the event that VIP Access By Tafer Resorts share information as mentioned above, try to limit the scope of the data supplied as necessary to carry out the specific function. Unless precluded by legal process, we require third parties to protect your information and comply with laws and regulations for the protection of personal data application.'
                },
                consent:{
                    title:'Consent',
                    paragraph1:'As part of our commitment to keep you informed, we may send letters or emails, communicate by telephone or other means to notify you of new products and services of VIP Access By Tafer Resorts along with upcoming special offers from TAFER RESORTS MANAGEMENT S.A. DE C.V... events, enhancements or other relevant information that may be of interest. You have the option not to accept delivery of all these communications of TAFER RESORTS MANAGEMENT S.A. DE C.V.. either following the directions included in our emails or other communications or by communicating directly with TAFER RESORTS MANAGEMENT S.A. DE C.V.',
                    paragraph2:'Our intentions are to keep all our guests and visitors informed and also allow them to reap the benefits offered by VIP Access By Tafer Resorts and its strategic partners. However, if you reside outside the United States, certain regulations on data protection may require us to obtain your permission to send you information you have not requested.',
                    paragraph3:'In some cases, your consent maybe implied by the nature of the service requested or transaction undertaken. As an example, VIP Access By Tafer Resorts may collect and use information that you voluntarily give us, as well as behavior data or information collected globally. Also, we will be deemed to have consented to receive those necessary communications to complete the transactions and provide the services requested.',
                    paragraph4:'In certain cases, we will request your explicit consent to collect information considered confidential subject to certain laws of privacy of information (e.g. information revealing racial or ethnic origin, political opinions or health conditions).',
                    paragraph5:'You may object to VIP Access By Tafer Resorts disclose your information to third parties except when:',
                    paragraph6:'(i) retained service providers to give services on behalf of VIP Access By Tafer Resorts (ii) the law seeks disclosure, (iii) disclosure data is necessary for reasons of public interest or national law, (iv) the disclosure is necessary to protect vital interests of the host, (v) the information is collected from publicly available sources, (vi) the disclosure is for retrospective processing of information for statistical or historical purposes or to improve the experiences of our customers or(vii) where a supervisory authority (as a defender of the rights of privacy, a data protection authority or similar authority) allows such disclosure.',
                },
                access:{
                    title:'Access to personally identifiable information',
                    paragraph1:'To ensure that your information is accurate and updated, we encourage you to regularly review and update such information as appropriate (for example, in the event of a change of address or email address or if you want to add other communication systems so that we may contact you). In an effort to prevent unauthorized disclosure of your information, you may be asked to provide a proof of your identity (or other test) along with the application form duly completed.',
                    paragraph2:'Occasionally, it is possible that the information you have requested to be removed is retained in certain files for a period of time in order to resolve any dispute properly or solve a problem. Also, some information may be kept indefinitely in the copies of "backup" or in the log files due to technical constraints, or financial or legal requirements.',
                    paragraph3:'Therefore, you cannot always expect to completely remove all your information from our database according to your request referred to the appropriate contact information listed below to contact us if you want to update your communication preferences, be removed from our marketing list or send a question or general request.'
                },
                measure:{
                    title:'Safety measures',
                    paragraph1:'VIP Access By Tafer Resorts recognizes the importance of information security and is constantly reviewing and improving standards and technical, physical and logical security procedures. All websites and servers owned byVIP Access By Tafer Resorts implemented security measures to help protect your data against accidents, loss, misuse, unauthorized or illegal access, disclosure or alteration while under our control. Although, there is no "guaranteed security" on or off the Internet, we protect your information using technical, appropriate, administrative and procedural safeguards among which are password controls, "firewalls" (and use of up to 256-bit encryption based on a Class 3Digital Certificate issued by VeriSign, Inc.',
                    paragraph2:'This allows us to use Secure Sockets Layer (SSL), an encryption method used to help protect your data from interception and hacking attempts while in transit).'
                },
                tecnologies:{
                    title:'Online technologies',
                    paragraph1:'VIP Access By Tafer Resorts and its business partners may use cookies, web beacons (web beacons) to obtain information about you while visiting our websites. A "cookie" is a small text file sent to your browser from a web server and stored on your'
                               +"computer's hard disk. Cookies that VIP Access By Tafer Resorts assigned a unique identifier to the computer, in turn, becomes your identification card whenever you visit a website of TAFER RESORTS MANAGEMENT S.A. DE C.V.",
                    paragraph2:'Cookies were designed to save you time because they help us to provide a personalized service without having to remind us of your preference search time when you visit our website. Cookies from VIP Access By Tafer Resorts are not designed to damage your files or to read the information that you stored on the hard drive of your computer.',
                    paragraph3:'Cookies can also help us provide you with a personalized online service in the following ways:',
                    opt1:'Remember your username and password for future visits easier and faster by logging.',
                    opt2:'Ensure that you have the appropriate frames and content.',
                    opt3:'Ensure you receive all the information requested.',
                    opt4:'Help us provide communications that are relevant and related to your interests and your location.',
                    paragraph4:'Cookies from VIP Access By Tafer Resorts are limited only to our Web sites and are not designed to follow while surfing the Internet once you have left the websites belonging to VIP Access By Tafer Resorts We track the usage of our websites in order to meet their needs and how best to help in the future, so you can find information more easily. Note that in addition to the "cookies" by VIP Access By Tafer Resorts it is possible that third parties place cookies on the hard drive of your computer. VIP Access By Tafer Resorts is not responsible for the presence or absence of "cookies" of third or technological features or practices that may use these third parties as to their cookies.',
                    paragraph5:'If you prefer not to enjoy the benefits offered by "cookies", you can configure your Internet browser to try these cookies in different ways. Depending on the type of browser you use, you can set your browser so that: (i) you are prompted to accept or reject cookies individually or(ii) avoid accepting any cookies. You should consult the supplier or manufacturer of your web browser for specific details about security related cookies. However, you should also consider that rejecting cookies may impede the achievement of certain transactions on our websites and our ability to recognize your browser from one visit to the next.',
                    paragraph6:'In addition, VIP Access By Tafer Resorts uses web markers used to count the number of people visiting certain websites. The information we collect is used with these markers in general and does not contain any personal data.VIP Access By Tafer Resorts can use this information to improve marketing programs and content.',
                    paragraph7:'We also work with suppliers and marketing partners that help us send custom advertising and produce content that we think will interest you. These vendors and other partners include advertisers, advertising agencies, advertising networks, specialists in market segmentation and exchange of data, analytics providers and other similar providers. These partners and providers may use cookies and Web bookmarks to collect information about your online activity (e.g. sites and pages you have visited in VIP Access By Tafer Resorts sites and other sites) to help VIP Access By Tafer Resorts (and, in some cases, external advertisers) to send a personalized content or advertising while in VIP Access By Tafer Resorts site or other sites.',
                    paragraph8:'For example, if you visit a site of VIP Access By Tafer Resorts and see one of our hotels or booking of a hotel room, you may see an advertisement later when visiting our other website. You may also ask you for any of these providers to send our advertising to consumers whose online behavior patterns in sites not VIP Access By Tafer Resorts indicate who might be interested in traveling. In other cases, we make special arrangements with certain advertisers that we think may be of interest to visitors to our website. These advertisers are allowed to send messages to the two brands (e. g. VIP Access By Tafer Resorts and brand advertiser) directly to consumers who have visited our web pages.'
                },
                websites:{
                    title:'Links to other websites',
                    paragraph1:'In order to anticipate their needs, VIP Access By Tafer Resorts offers links to other websites and information for your benefit. VIP Access By Tafer Resorts is not responsible for the content that facilitates or included in any independent website, including, without limitation, any claim advertising or business practices. While VIP Access By Tafer Resorts will protect the information found on the websites owned and operated, we cannot control nor assume any responsibility for the privacy practices of third party websites, including websites owned or controlled by independent franchisees and other owners of hotels, resorts, timeshare or residential properties that may use the name of a brand of VIP Access By Tafer Resorts or websites not controlled and authorized by VIP Access By Tafer Resorts The third-party websites that are accessed through links on our websites have privacy practices and data collection, as well as various security measures. We assume no responsibility for the practices, policies and security measures implemented by third parties on their websites.',
                    paragraph2:'We encourage you to contact and ask them about their privacy practices, policies and security measures before disclosing any information. We recommend you to review the privacy policies and statements relevant to websites related to understand how those sites collect, manipulate and store information.'
                },
                minors:{
                    title:'Minors',
                    paragraph1:'VIP Access By Tafer Resorts does not seek or wish to receive information directly from minors. However, we cannot always determine the age of persons who access and use our websites. In the event that a minor (defined according to the criteria established by applicable law) provides us with your data without the consent of their parents or guardians, we ask the parent or guardian to contact us and ask us to delete this information and unsubscribe by the minor regarding future commercial communications from TAFER RESORTS MANAGEMENT S.A. DE C.V.'
                },
                cookies:{
                    title:'Cookies',
                    paragraph1:'Cookies are used by VIP Access By Tafer Resorts to evaluate visits to the website in order to improve the experience offered. In technical terms, cookies are a packet of data that is sent between the Internet server and browser in order to identify the user and evaluate their access to the website. The small “cookie” file is transferred to your computer when you visit the website. Any transferred cookies only contain the information that VIP Access By Tafer Resorts sends to your computer. Even though the cookies help us to identify your computer, no private data can be read with the cookies. If you wish to deactivate the cookies function, you may do so by changing the settings on your browser. However, by deactivating the cookies, your experience of the website may be diminished as you may not be able to see all the features displayed correctly or access some functions.'
                },
                using:{
                    title:'By using our website you accept our use of cookie',
                    contactUs:'Contact Us',
                    update:'Update your communication preferences.',
                    paragraph1:'For questions or suggestions regarding our privacy practices of information regarding this Privacy Statement, or if you want to update, modify or cancel marketing communications from VIP Access By Tafer Resorts you can contact us by any of the ways listed below:',
                    byPhone:'By phone:',
                    phone:'+52 322 327 3663'
                }
            }
        },
        update:{
            title:'Actualizacion Disponible',
            content:"Vamos a actualizar VIP Access para disfrutar las ultimas novedades.",
        },
        conciergeWidget:{
            customer:'Horario de Atención desde 7:30 am a 9:00 pm'
        },
        banners:{
            banner50:{
                welcome:'Bienvenido a Bono Virtual',
                content:
                    '<p content-text ms-3 me-3 fs-6>¡Felicidades! Usted ha recibido un regalo virtual como bienvenida a '+
                    '<label style="color:var(--vip-gold); font-weight: 500;">VIP Access</label>,'+
                    ' aproveche su bono en el Local Channel o en Residence Club.</p>',
                disclaimer:'Solo válido en el Local Channel o en Residence Club. Pregunte a su concierge cómo hacer válido su bono de bienvenida. Las promociones son válidas durante su estancia en nuestro resort Garza Blanca & Mousai Puerto Vallarta. La promoción solo es válida por habitación y por su tiempo de estancia.'
            },
            karumabanner:{
                content:
                '<p style="font-size: clamp(1rem, 2vw + 1rem, 1rem) !important;margin-bottom:0!important;line-height:1.9;">Seize this opportunity to savor the finest in culinary mastery and elevated luxury at '+
                '<label style="font-size: clamp(1rem, 2vw + 1rem, 1rem) !important;color:var(--vip-gold); font-weight: 500;">Karuma Restaurant.</label></p>'+
                '<p style="font-size: clamp(1rem, 2vw + 1rem, 1rem) !important;margin-bottom:0!important;line-height:1.9;">Secure your reservation today and receive the remarkable 10% discount on your bill. We eagerly anticipate your arrival.</p>',
                restaurant:'Karuma The Art of Grill',
                quote:'Best Steakhouse in Puerto Vallarta'
            },
            session:{
                content:'<p>Capture the best of your vacation with your loved ones and live the experience of a '+
                '<label style="color:var(--vip-gold); font-weight: 500;"> professional photo session</label>'+
                ' at this iconic resort.</p>'
            },
            spa:{
                content:"<p class='lh-base'>Spa Imagine sits in pride as the only 5 diamond luxury spa in Jalisco. Don't miss the opportunity to book your appointment.</p>"
            }
        }
    }
}

const i18n= createI18n({
    locale:'en-US',
    messages
});

export default i18n