// const state = () => ({    
// });

const state = () => ({
    // firstName: '',
    // lastName: '',
    // leadID: '',
    // role: '',
    // avatar: '',
    myLiveChat: {
        loaded: false,
        hidden: true,
        clicked: false,
    },
});

export default state;