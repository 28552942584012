// export const myGetter = (state) => {

//     //Todo

// };

// export const fullUserName = ({ firstName, lastName }) => {

//     return `${firstName} ${lastName}`;

// }

// export const avatar = (({ avatar }) => {

//     return avatar;

// })

export const myLiveChatLoded = (({ myLiveChat }) => myLiveChat.loaded);

export const myLiveChatClicked = (({ myLiveChat }) => myLiveChat.clicked);

export const myLiveChatHidden = (({ myLiveChat }) => myLiveChat.hidden);