<template>
  <nav class="navbar position-fixed navbar-expand-lg navbar-dark py-0"  :class="headerClass">
    <div id="navbarMob" class="container-fluid custom-size">
      <div class="row w-100">
        <div class="col-12">
          <div class="row justify-content-between py-lg-2">
            <router-link class="col-auto navbar-brand py-2 py-lg-0" to="/">
              <img src="@/Content/images/logos/logo_vip.svg" class="img-fluid text-center d-none d-sm-none d-md-none d-md-block d-lg-block d-xl-block d-xxl-block" />
              <img src="@/Content/images/logos/md-vip.svg" class="img-fluid text-center d-xms-block d-sm-block d-md-block d-lg-none d-xl-none d-xxl-none">
            </router-link>
            <div class="d-flex col-auto col-md-auto">
              <!-- <select v-model="language"
                class="menu-text select-language bg-transparent d-xms-block d-sm-block d-md-block d-lg-none me-3"
                @change="switchLanguage(this.value)">
                <option value="en-US">ENG</option>
                <option value="es-MX">ESP</option>
              </select> -->
              <v-select 
              class="d-xms-block d-sm-block d-md-block d-lg-none mx-auto my-auto ms-1 me-4" 
              :on-change="switchLanguage()" 
              v-model="language" 
              :reduce="(option) => option.id" 
              :options="[
                { label: 'ENG', id: 'en-US' },
                { label: 'ESP', id: 'es-MX' },
              ]"
                />
            <button class="navbar-toggler cursor-pointer border-0 btn-collapse" aria-controls="navBarCollapse"
              aria-expanded="false" aria-label="Toggle navigation" data-bs-toggle="collapse" data-bs-target="#navbarVip">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            </div>
            <div class="col-auto collapse navbar-collapse" id="navbarVip">
              <ul class="
                  navbar-nav
                  text-white
                  me-auto
                  mb-2 mb-lg-0
                  w-100
                  text-center
                  menu-mb
                  justify-content-center justify-content-lg-end
                ">
                <li class="nav-item" :class="route.parentClass || 'order-2 order-lg-0'" v-for="route in navigationRoutes"
                  :key="route.id">
                  <router-link class="nav-link" :class="route.class" :to="{ name: route.name }">
                    <template v-if="route.id==1">
                      {{$t('header.nav.home')}}
                    </template>
                    <template v-if="route.id==2">
                      {{$t('header.nav.property')}}
                    </template>
                    <template v-if="route.id==3">
                      {{$t('header.nav.exclusive')}}
                    </template>
                    <template v-if="route.id==4">
                      {{$t('header.nav.rewards')}}
                    </template>
                    <template v-if="route.id==5">
                      {{$t('header.nav.residence')}}
                    </template>
                    <template v-if="route.id==6">
                      {{$t('header.nav.press')}}
                    </template>
                  <!-- {{route.label}} -->
                  </router-link>
                </li>
                <v-select class="d-none d-lg-block" :on-change="switchLanguage()" v-model="language" :reduce="(option) => option.id" :options="[
                      { label: 'ENG', id: 'en-US' },
                      { label: 'ESP', id: 'es-MX' },
                    ]"
                />
                <!-- <select v-model="language" class="select-language menu-text  ms-0 bg-transparent d-none d-lg-block"
                  @change="switchLanguage(this.value)">
                  <option value="en-US">ENG</option>
                  <option value="es-MX">ESP</option>
                </select> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import { Collapse } from "bootstrap";
export default {
  data() {
    return {
      collapse: null,
      navigationRoutes: [
        { id:1, name: "Home", label:this.$t('header.nav.home')},
        { id:2, name: "TheProperty", label: this.$t('header.nav.property')},
        { id:3, name: "Exclusive", label: this.$t('header.nav.exclusive')},
        // { id:4, name: "Rewards", label: "TAFER REWARDS" },
        { id:5, name: 'ResidenceClub', label: this.$t('header.nav.residence')},
        { id:6, name: "DedicatedPress", label:  this.$t('header.nav.press')},
      ],
      scrollPosition:0,
      language: '',
      showToggleBar:null
    };
  },
  components:{},
  methods: {
    hide() {
      this.collapse.hide();
    },
    toggle() {
      this.collapse.toggle();
    },
    onShowNavBar() {
      document.body.setAttribute("show-nav-bar", true);
      this.showToggleBar=true;
    },
    onHideNavBar() {
      document.body.setAttribute("show-nav-bar", false);
      this.showToggleBar=false;
    },
    switchLanguage(){
      this.$i18n.locale=this.language;
    }
  },
  mounted() {
    const collapse = this.$el.querySelector("#navbarVip");

    this.collapse = new Collapse(collapse, {
      toggle: false
    });

    collapse.addEventListener("show.bs.collapse", this.onShowNavBar);
    collapse.addEventListener("hide.bs.collapse", this.onHideNavBar);

    this.$router.afterEach(() => {
      this.hide();
    });

    window.addEventListener('scroll',()=>{
      this.scrollPosition=window.scrollY;
    })

    this.language=navigator.language.includes('es') ? 'es-MX' : 'en-US';
    this.$i18n.locale=this.language;

    if(window.screen.width<=991){
      const mobileNavbar = this.$el.querySelector("#navbarMob");
        mobileNavbar.classList.remove("custom-size");
    }
  },
  unmounted() {
    const { _element } = this.collapse;
    _element.removeEventListener(
      "show.bs.collapse",
      this.onShowNavBar
    );
    _element.removeEventListener(
      "hide.bs.collapse",
      this.onHideNavBar
    );
  },
  computed:{
    headerClass(){
      
      if((this.scrollPosition>(window.innerHeight*.90))){
        return this.showToggleBar==true ? 'backgroundContent h-100' : 'backgroundContent';
      }else
      {
        return this.showToggleBar==true ? 'backgroundContent h-100':'bgContent-out';
      }
      // return (this.scrollPosition>(window.innerHeight*.90) || this.showToggleBar==true) ? 'backgroundContent':'';
    },
    // toggleMenuWidth(){
    //   const mobileNavbar = this.$el.querySelector("#navbarMob");
    //   if(window.screen.width<=991){
    //     console.log('version movil');
    //     mobileNavbar.classList.remove("custom-size");
    //     // if(this.showToggleBar==true){
    //     //   // mobileNavbar.classList.add()
    //     //   mobileNavbar.classList.remove("mystyle");
    //     // }else{
    //     //   mobileNavbar.classList.add()
    //     // }
    //   }
    // }
  }
};
</script>
<style scoped>
.backgroundContent {
  background-color: #11111D;
}

.bgContent-out{
  background-color: transparent;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.menu-mb{
  font-family: var(--unnamed-font-family-gravesend-sans);
  font-style: var(--unnamed-font-style-normal);
  font-weight: var(--unnamed-font-weight-medium);
  font-size: 14px;
  color:var(--unnamed-color-ffffff)
}

#navbarVip {
  /* background-color: #0F0F17; */
  background-color: #11111D;
}

body[show-nav-bar="true"]{
  overflow:hidden;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--som-pink);
}

#navbarVip .navbar-nav {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 9.5rem);
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0.5rem 1rem;
}

.navbar-dark .navbar-nav .nav-link.login {
  background-color: #ededea;
  border-radius: 5px;
}

.nav-link {
  display: inline-block;
  padding: 0.5rem 1rem;
}

.navbar-dark .navbar-toggler-icon {
  background-image: none;
}

.navbar-toggler {
  width: 40px;
  height: 30px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 1rem 1rem 0 0;
}

.navbar-toggler span {
  color: white;
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: white;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navbar-toggler span:nth-child(1) {
  top: 0px;
}
.navbar-toggler span:nth-child(2),
.navbar-toggler span:nth-child(3) {
  top: 12px;
}
.navbar-toggler span:nth-child(4) {
  top: 24px;
}

.navbar-brand>img {
  margin-left: 12px;
  height: 40px !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;  
}


.select-language{
  border: none;
  background: transparent;
  outline: none;
}
.select-language option{
  background-color: transparent !important;
  color: #000;
  /* color:var(--vip-gold);
	border: 1px solid #e4e4e4;
	-webkit-appearance: none; 
  -moz-appearance: none; */
}

/* @media (min-width: 321px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 0.75rem 1rem;
  }
} */

@media (max-width:991px) {
  .menu-mb {
      font-family: var(--unnamed-font-family-gravesend-sans);
      font-style: var(--unnamed-font-style-normal);
      font-weight: var(--unnamed-font-weight-medium);
      font-size: clamp(1.125rem, 1vw + 0.75rem, 1.5rem);
      line-height: 4;
      color: var(--unnamed-color-ffffff)
    }
}
@media (min-width: 992px) {
  #navbarVip {
    background-color: transparent;
  }
  /* .menu-mb {
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: clamp(0.688rem, -3vw + 2.25rem, 0.938rem);
    color: var(--unnamed-color-ffffff)
  } */

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .bi {
    color: white;
  }

  #navbarVip .navbar-nav {
    display: flex;
    flex-direction: initial;
    height: auto;
  }

  .navbar-dark .navbar-nav .nav-link.signup {
    background-color: var(--som-rio-grande);
    border-radius: 5px;
  }

  .navbar-dark .navbar-nav .nav-link.login {
    background-color: transparent;
  }

  .navbar-dark .navbar-nav .nav-link.signup:hover,
  .navbar-dark .navbar-nav .nav-link.signup:focus {
    background-color: #8b9200;
  }

  .navbar-dark .navbar-nav .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .container-fluid.custom-size {
    width: 99%;
  }
  /* .menu-text{
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 11px;
    color:var(--unnamed-color-ffffff)
  } */
}

@media (min-width: 1200px) {
  .navbar-brand>img {
    height: 50px !important;
  }
  .navbar.container-fluid{
    width: 85%;
  }
  .container-fluid.custom-size {
    width: 85%;
}
  .menu-text{
    font-family: var(--unnamed-font-family-gravesend-sans);
    font-style: var(--unnamed-font-style-normal);
    font-weight: var(--unnamed-font-weight-medium);
    font-size: 14px;
    color:var(--unnamed-color-ffffff)
  }
}
</style>
